/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import logo from '../../../../../assets/img/logo.png';
import location from '../../../../../assets/img/location.svg';
import mail from '../../../../../assets/img/mail.svg';
import phone from '../../../../../assets/img/phone-call.svg';
import { Link } from 'react-router-dom';

function index() {
  
  return (
    <footer className="bg-black xl:px-16 2xl:px-32 px-8 ">
      <div className="flex flex-col lg:flex-row justify-between items-center pt-9 bg-black">
        <div className="flex mobile md:mr-8 lg:flex-col justify-between relative lg:top-4 w-full lg:w-auto ">
          <Link to="/home" className='flex items-center cursor-pointer lg:-mt-12'>
            <img
              src={logo}
              loading="lazy"
              alt="StategyConnectLogo"
              className="h-14 w-14 cursor-pointer"
            />
            <p className={`nunito text-white text-base ml-1.5 font-bold relative top-0.5 cursor-pointer`}>
              Salunke Maratha
            </p>
          </Link>
        </div>

        <div className={`text-white lg:pr-10 2xl:pr-20 xl:pr-10  relative top-2 -left-5 lg:h-56 `}>
          <p className="text-base hidden lg:flex font-semi-bold">Contact Us</p>
          <div className="flex flex-col">
            <div className="no-underline grey-text text-sm mb-0 text-left md:mt-4 mt-8 flex font-thin">
              <img
                src={location}
                loading="lazy"
                alt="location"
                className="self-start"
              />
              <span>
                <p className="ml-6 w-64 lg:w-48 xl:w-80">
                  Behind Tirupati Hardware, 71, Sariputra Lay-Out, Sudarshan Nagar, New Narasala Road,
                  Rajapeth, S O, Nagpur, Maharashtra, 440034
                </p>
              </span>
            </div>
            <div className="no-underline grey-text text-sm flex  md:mt-4 mt-8 text-left font-thin">
              <img src={mail} loading="lazy" alt="location" className="" />
              <p className="ml-6">pankajoanlj@gmail.com</p>
            </div>
            <div className="no-underline grey-text text-sm flex  md:mt-6 mt-6 font-thin">
              <img src={phone} loading="lazy" alt="location" className="" />
              <p className="ml-6">+91 9226590907</p>
            </div>
          </div>
        </div>
      </div>

      <div className="py-3 mt-8 bg-black top-divide-line">
        <p className="text-center grey-text text-sm">
          Copyright @ 2021 Salunke Maratha All Rights Reserved.
        </p>
      </div>
    </footer>
  );
}

export default index;
