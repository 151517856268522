/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import rocket from '../../assets/img/rocLaunch1.png';
import loader from '../../assets/img/loader.gif';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers';
import * as Yup from 'yup';
import { baseApi } from '../../services/constants';
import ResetPassword from './ResetPassword';

import Navbar from '../Home/SmallHome/components/Navbar';
import Footer from '../Home/SmallHome/components/Footer';

const axios = require('axios');

export default function ForgetPassword(props) {

  const [username, setUsername] = useState('');
  const [isResponse, setIsResponse] = useState(false);
  const [isResponseStatus, setIsResponseStatus] = useState('');
  const [isResponseMessage, setIsResponseMessage] = useState('');
  const [isSubmit, setIsSubmit] = useState(false);

  const closePopup = () => {
    props.setVisible(false)
  }

  const closeResetPassword = () => {
    setIsResponse(false);
    setIsResponseStatus('');
    setIsResponseMessage('');
  }

  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .required('Email or Mobile is required')
  });

  const { register, handleSubmit, reset, errors } = useForm({
    resolver: yupResolver(validationSchema)
  });

  async function onSubmit(data) {
    console.log(data)
    setIsSubmit(true);
    await setUsername(data.username)
    await axios({
      method: "get",
      url: `${baseApi}user/forgetpassword/${data.username}`
    })
      .then(result => {
        if (result.data) {
          setIsResponse(true);
          setIsResponseStatus(result.data.status);
          setIsResponseMessage(result.data.message);
          setIsSubmit(false);
        }
      })
  }

  return (
    <>
      <Navbar />
      { 
        !isResponse ? 
        <div className="flex relative rounded h-auto bg-white">
          <div className="hidden xl:block" style={{ width: 400 }}>
            <img src={rocket} loading="lazy" alt="" className="z-10 relative -left-0.5" />
          </div>
          <div className="w-9/12 w-11/12 mx-auto">
            <div className="w-full flex-col flex items-center">
              <div>
                <div className="md:grid md:grid-cols-6 md:gap-6">
                  <div className="md:col-span-1">
                    <div className="px-4 sm:px-0 mt-4"></div>
                  </div>
                  <div className="mt-3 md:mt-0 md:col-span-6 sm:col-span-6 md:col-span-6 lg:col-span-6">
                    <form onSubmit={handleSubmit(onSubmit)} onReset={reset} className="w-full">
                      <div className="shadow sm:rounded-md sm:overflow-hidden">
                        <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                          <div className="grid grid-cols-6 gap-6">
                            <div className="col-span-6 sm:col-span-6 sm:col-span-6">
                              <h3 className="text-2xl font-bold leading-6 text-gray-900 text-center">Forget Password</h3>
                              <p className="mt-1 text-sm text-gray-600 text-center mt-2">Get OTP to set new password</p>
                            </div>
                            <div className="col-span-6 sm:col-span-6 sm:col-span-6">
                              <input
                                ref={register}
                                type="text"
                                className="border focus:outline-none border-gray-300 py-2 lg:px-6 px-2 rounded w-full text-sm" placeholder="First Name *"
                                placeholder="Username *"
                                name="username"
                              />
                              <p className="text-red-500 font-semibold text-md mt-2">{errors.username?.message}</p>
                            </div>
                            <div className="col-span-6 sm:col-span-6 sm:col-span-6">
                              {
                                isResponse ?
                                  <div className={`${isResponseStatus === 'success' ? 'text-green-500' : 'text-red-500'} capitalize font-semibold text-md mt-0 mb-4 text-center`}>
                                    {isResponseMessage}
                                  </div> :
                                  null
                              }
                              <button type="submit" style={{ backgroundColor: "#F74300" }}
                                className="py-2 px-4 w-full text-center rounded text-white text-sm cursor-pointer"
                              >
                                {
                                  isSubmit ?
                                    <img src={loader} alt={loader} style={{ width: '1.2rem', left: '50%', position: 'relative' }} /> :
                                    'Send OTP'
                                }
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>:
        <ResetPassword username={username}/>  
      }
      <Footer />
    </>
  );
}