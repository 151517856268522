import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import './App.css';

import Home from './components/Home';
import Contact from './components/Contact';
import Members from './components/Members';
import Profile from './components/Profile';
import EditProfile from './components/EditProfile';
import UserDetails from './components/UserDetails';
import SuccessStory from './components/SuccessStory';
import SignUp from './components/SignUp';
import Login from './components/Login';
import ForgetPassword from './components/ForgetPassword';
// import NotFound from './components/NotFound';

function App() {
  return (
    <BrowserRouter>
      <Route exact path="/" component={Members} />
      <Route exact path="/home" component={Home} />
      <Route path="/signup" component={SignUp} />
      <Route path="/login" component={Login} />
      <Route path="/forgetpassword" component={ForgetPassword} />
      <Route path="/contact" component={Contact} />
      <Route path="/members" component={Members} />
      <Route path="/profile" component={Profile} />
      <Route path="/edit-profile" component={EditProfile} />
      <Route path="/user/:id" component={UserDetails} />
      <Route path="/success-story" component={SuccessStory} />
      {/* <Route exact path="/*" component={NotFound} /> */}
    </BrowserRouter>
  );
}

export default App;
