import jwt_decode from "jwt-decode";
import axios from "axios";
import { baseApi } from "./constants";

export const isLogin = () => {
  let user = localStorage.getItem("access_token");
  if(user){
    return true;
  }
  return false;
}

export const isAdmin = () => {
  let decoded = jwt_decode(localStorage.getItem("access_token"));
  if(decoded.role === "ADMIN"){
    return true;
  }
  return false;
}


export const userDetails = () => {
  let decoded = jwt_decode(localStorage.getItem("access_token"));
  if(decoded){
    return decoded;
  }
  return false;
}

export const sessionUser = async () => {
  if(localStorage.getItem("access_token")){
    return await axios({
      method: "get",
      url: `${baseApi}user/userprofile`,
      headers: { Authorization: localStorage.getItem("access_token") }
    })
  }
}

export async function getMemberDetails(id) {
  return await axios({
    method: "get",
    url: `${baseApi}user/getuser/${id}`,
  })
}

export async function deleteMember(id) {
  return await axios({
    method: "delete",
    url: `${baseApi}user/deleteuser/${id}`,
    headers: { Authorization: localStorage.getItem("access_token") }
  })
}