import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import SearchFilter from '../SearchFilter';
import Dialog from '@material-ui/core/Dialog';
import loader from '../../../assets/img/loader.gif';
import { sessionUser } from '../../../services/auth';
import { baseApi } from '../../../services/constants';
import noRecords from '../../../assets/img/undraw-hiring-cyhs.svg';
import { MailIcon, PhoneIcon, UserIcon, CalendarIcon, ArrowSmLeftIcon, ArrowSmRightIcon, AtSymbolIcon, BadgeCheckIcon } from '@heroicons/react/solid';
import InfiniteScroll from "react-infinite-scroll-component";

const paginationItem = 10;

export default class SearchFilterResult extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      accountRole: '',
      isSubscribed: false,
      user: [],
      perPageItem: paginationItem,
      fromItem: 1,
      toItem: paginationItem,
      currentPageNumber: 1,
      totalPagenumber: 0,
      totalDataItem: 0,
      isLoading: false,
      filterparams: null,
      windowScreen: null,
      open: false
    };
    this.getUser = this.getUser.bind(this);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }

  componentDidMount() {
    this.setState({ windowScreen: window.screen.width })
    if (localStorage.getItem("access_token")) {
      sessionUser().then(res => {
        new Date(res.data.user.subscription.razorpay_payment_date) > new Date() ?
          this.setState({ isSubscribed: true }) :
          this.setState({ isSubscribed: false })
        this.setState({ accountRole: res.data.user.role })
      });
    }
    this.getUser();
  }

  async getUser(data) {
    this.setState({ filterparams: data })
    this.setState({ isLoading: true });
    let itemIndex = (this.state.currentPageNumber - 1) * this.state.perPageItem;
    await axios({
      method: 'post',
      url: `${baseApi}user/getalluser?per_page=${this.state.perPageItem}&from=${itemIndex}`,
      data: data,
    })
      .then(result => {
        if (result.data && result.data.user.length > 0) {
          this.setState({ totalPagenumber: Math.ceil(result.data.count / 50) });
          this.setState({ totalDataItem: result.data.count });
          this.setState({ user: [...this.state.user, ...result.data.user] });
          this.setState({ isLoading: false });
          this.setState({ open: false });
          // window.scrollTo(0, 0)
        }
        else {
          this.setState({ user: [] });
        }
      })
      .catch(error => {
        this.setState({ user: [] });
      });
  }

  async next() {
    await this.setState({ currentPageNumber: this.state.currentPageNumber + 1 });
    this.setState({ fromItem: this.state.fromItem + this.state.perPageItem });
    this.setState({ toItem: this.state.toItem + this.state.perPageItem });
    this.getUser(this.state.filterparams);
  }

  async previous() {
    await this.setState({ fromItem: this.state.fromItem - this.state.perPageItem });
    this.setState({ toItem: this.state.toItem - this.state.perPageItem });
    this.setState({ currentPageNumber: this.state.currentPageNumber - 1 });
    this.getUser(this.state.filterparams);
  }

  render() {

    let noRecordsView = <div style={{
      textAlign: 'center',
      padding: '4rem',
      background: '#F8F8F8',
      margin: '1rem 0'
    }}> <img src={noRecords} alt={noRecords} style={{ margin: 'auto' }} /> <h5> No data </h5>
    </div>

    let from = this.state.fromItem;
    let to = this.state.toItem;

    return (
      <>
        <p className="text-left text-4xl xl:text-4xl my-8 font-bold"> Members
          <button onClick={() => this.setState({ open: true })}
            style={{
              backgroundColor: "#f74300",
              borderolor: "1px solid #f74300",
              color: "#fff",
              fontWeight: "700",
              padding: '0.2rem 1.5rem',
              borderRadius: "0.5rem",
              float: "right",
              fontSize: "1.2rem"
            }}
          >
            Filters
          </button>
        </p>
        <Dialog
          style={{ width: "100%", padding: "1rem" }}
          open={this.state.open}
          onClose={() => this.setState({ open: false })}
        >
          <SearchFilter getUser={this.getUser} closeFilter={() => this.setState({ open: false })} />
        </Dialog>

        <div className="row">
          <div className="col-md-12 mb-8">
            <div className="overflow-x-auto lg:overflow-x-none">
              {
                this.state.windowScreen > 640 ?
                  <div className="py-2 align-middle inline-block min-w-full">
                    <InfiniteScroll
                      dataLength={this.state.user.length}
                      next={this.next}
                      hasMore={true}
                      loader={<h4>Loading...</h4>}
                    >
                      {
                        this.state.user.length > 0 ?
                          this.state.user.map((item, index) => {
                            return (
                              <div key={index} className="p-4 overflow-hidden m-2 border border-gray-200 sm:rounded-lg">
                                <div className="flex items-center">
                                  <div className="flex-shrink-0 h-10 w-10">
                                    {
                                      item.avatar ?
                                        <a href={item.avatar} target="blank">
                                          <img className="h-10 w-10 rounded-full" src={item.avatar} alt={item.first_name + item.last_name} />
                                        </a> :
                                        <span className="inline-block h-12 w-12 rounded-full overflow-hidden bg-gray-100">
                                          <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                          </svg>
                                        </span>
                                    }
                                  </div>
                                  <div className="ml-4 w-48">
                                    <div className="text-lg font-bold capitalize" style={{ color: "#F74300", fontWeight: "900" }}>
                                      <Link to={`/user/${item._id}`} target="blank">
                                        {
                                          (this.state.accountRole === "ADMIN" || this.state.isSubscribed) ?
                                            item.first_name : ''
                                        } {item.last_name}
                                      </Link>
                                    </div>
                                  </div>
                                  {
                                    this.state.accountRole === "ADMIN" ?
                                      <div className="ml-4 w-24">
                                        <div className="text-lg font-bold text-gray-900">
                                          {
                                            (new Date(item.subscription.razorpay_payment_date) > new Date()) ?
                                              <BadgeCheckIcon className="h-7 w-7 mr-3 inline ml-4 text-green-500" /> :
                                              <BadgeCheckIcon className="h-7 w-7 mr-3 inline ml-4 text-gray-500" />
                                          }
                                        </div>
                                      </div> :
                                      null
                                  }
                                  <div className="ml-4 w-48">
                                    <div className="text-sm text-gray-500">
                                      <AtSymbolIcon className="h-5 w-5 mr-3 inline" />
                                      {item.username ? item.username : "N/A"}
                                    </div>
                                  </div>
                                  {
                                    (this.state.accountRole === "ADMIN" || this.state.isSubscribed) ?
                                      <div className="ml-4 w-48">
                                        <div className="text-sm text-gray-500">
                                          <MailIcon className="h-5 w-5 mr-3 inline" />
                                          {item.email ? item.email : "N/A"}
                                        </div>
                                      </div> :
                                      null
                                  }
                                  {
                                    (this.state.accountRole === "ADMIN" || this.state.isSubscribed) ?
                                      <div className="ml-4 w-36">
                                        <div className="text-sm text-gray-500">
                                          <PhoneIcon className="h-5 w-5 mr-3 inline" />
                                          {item.phone ? item.phone : "N/A"}
                                        </div>
                                      </div> :
                                      null
                                  }
                                  <div className="ml-4 w-36">
                                    <div className="text-sm text-gray-500">
                                      <UserIcon className="h-5 w-5 mr-3 inline" />
                                      {item.gender ? item.gender : "N/A"}
                                    </div>
                                  </div>
                                  <div className="ml-4 w-36">
                                    <div className="text-sm text-gray-500">
                                      <CalendarIcon className="h-5 w-5 mr-3 inline" />
                                      {item.dob ? `${new Date(item.dob).getDate()}/${new Date(item.dob).getMonth() + 1}/${new Date(item.dob).getFullYear()}` : "N/A"}
                                    </div>
                                  </div>

                                  <div className="ml-4 w-48">
                                    <div className="text-sm text-gray-500">
                                      Height: {item.height ? item.height : "N/A"} Feet
                                    </div>
                                  </div>

                                  <div className="ml-4 w-48">
                                    <div className="text-sm text-gray-500">
                                      Home Town : {item.home_town ? item.home_town : "N/A"}
                                    </div>
                                  </div>

                                  <div className="ml-4 w-48">
                                    <div className="text-sm text-gray-500">
                                      Current City : {item.current_city ? item.current_city : "N/A"}
                                    </div>
                                  </div>

                                  <div className="ml-4 w-48">
                                    <div className="text-sm text-gray-500">
                                      Edu. : {item.education_title ? item.education_title : "N/A"}
                                    </div>
                                  </div>

                                  <div className="ml-4 w-48">
                                    <div className="text-sm text-gray-500">
                                      Occ. : {item.occupation_title ? item.occupation_title : "N/A"}
                                    </div>
                                  </div>

                                  <div className="ml-4 w-48">
                                    <div className="text-sm text-gray-500">
                                      Salary : {item.salary ? item.salary : "N/A"}
                                    </div>
                                  </div>

                                </div>
                              </div>
                            )
                          }) : noRecordsView
                      }
                    </InfiniteScroll>
                  </div> :
                  <div className="py-2 align-middle inline-block min-w-full">
                    <InfiniteScroll
                      dataLength={this.state.user.length}
                      next={this.next}
                      hasMore={true}
                      loader={<h4>Loading...</h4>}
                    >
                      {
                        this.state.user.length > 0 ?
                          this.state.user.map((item, index) => {
                            return (
                              <div className="lg:flex lg:items-center border-b border-gray-400 pb-4 mb-4 ml-2 lg:mb-8 xl:mb-8 lg:justify-between">
                                {
                                  item.avatar ?
                                    <div className="flex -space-x-1 overflow-hidden relative">
                                      <a href={item.avatar} target="blank">
                                        <img
                                          className="h-16 w-16 rounded-full"
                                          src={item.avatar}
                                          alt={item.first_name + item.last_name}
                                        />
                                      </a>
                                    </div> :
                                    <span className="inline-block mr-8 h-16 w-16 rounded-full overflow-hidden bg-gray-100 mr-4">
                                      <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                        <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                      </svg>
                                    </span>
                                }
                                <div className="flex-1 min-w-0 ml-0 lg:ml-8 mt-8 lg:mt-0">
                                  <h2 className="text-lg font-bold capitalize" style={{ color: "#F74300", fontWeight: "900" }}>
                                    <Link to={`/user/${item._id}`} target="blank">
                                      {
                                        (this.state.accountRole === "ADMIN" || this.state.isSubscribed) ?
                                          item.first_name : ''
                                      } {item.last_name}
                                    </Link>
                                    {
                                      this.state.accountRole === "ADMIN" ? (
                                        (new Date(item.subscription.razorpay_payment_date) > new Date()) ?
                                          <BadgeCheckIcon className="h-5 w-5 mr-3 inline ml-2 text-green-500" /> :
                                          <BadgeCheckIcon className="h-5 w-5 mr-3 inline ml-2 text-gray-500" />
                                      ) :
                                        null
                                    }
                                  </h2>
                                  <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
                                    <div className="mt-2 flex items-center text-sm text-black-700">  Username: {item.username} </div>
                                    {
                                      (this.state.accountRole === "ADMIN" || this.state.isSubscribed) ?
                                        <div className="mt-2 flex items-center text-sm text-black-700">
                                          Email: {item.email ? item.email : "N/A"}
                                        </div> :
                                        null
                                    }
                                    {
                                      (this.state.accountRole === "ADMIN" || this.state.isSubscribed) ?
                                        <div className="mt-2 flex items-center text-sm text-black-700">
                                          Phone: {item.phone ? item.phone : "N/A"}
                                        </div>
                                        : null
                                    }
                                    <div className="mt-2 flex items-center text-sm text-black-700"> Gender: {item.gender} </div>
                                    <div className="mt-2 flex items-center text-sm text-black-700">
                                      Date of Birth: {`${new Date(item.dob).getDate()}/${new Date(item.dob).getMonth() + 1}/${new Date(item.dob).getFullYear()}`}
                                    </div>
                                    <div className="mt-2 flex items-center text-sm text-black-700"> Height: {item.height ? item.height : "N/A"} Feet </div>
                                    <div className="mt-2 flex items-center text-sm text-black-700"> Home Town : {item.home_town ? item.home_town : "N/A"} </div>
                                    <div className="mt-2 flex items-center text-sm text-black-700"> Current City : {item.current_city ? item.current_city : "N/A"} </div>
                                    <div className="mt-2 flex items-center text-sm text-black-700"> Edu. : {item.education_title ? item.education_title : "N/A"} </div>
                                    <div className="mt-2 flex items-center text-sm text-black-700"> Occ. : {item.occupation_title ? item.occupation_title : "N/A"} </div>
                                    <div className="mt-2 flex items-center text-sm text-black-700"> Salary : {item.salary ? item.salary : "N/A"} </div>
                                  </div>
                                </div>
                              </div>
                            )
                          }) : noRecordsView
                      }
                    </InfiniteScroll>
                  </div>
              }
            </div>
            {/* {
              this.state.user.length > 0 ?
                <div style={{ fontSize: '0.9rem', backgroundColor: "#F74300", float: 'right', marginTop: "2rem", padding: '1rem' }}>
                  {
                    !this.state.isLoading ?
                      <ul style={{ margin: 0, padding: 0, fontFamily: 'Overpass, sans-serif', color: '#fff' }}>
                        <li style={{ display: "inline", marginRight: "3px" }}>{from < 9 ? `0${from}` : from}</li>
                        <li style={{ display: "inline", marginRight: "3px" }}> - </li>
                        <li style={{ display: "inline", marginRight: "3px" }}>{to < this.state.totalDataItem ? to : this.state.totalDataItem}</li>
                        <li style={{ display: "inline", marginRight: "3px" }}> of </li>
                        <li style={{ display: "inline", marginRight: "3px" }}> {this.state.totalDataItem}</li>
                        <li style={{ display: "inline", marginRight: "3px" }}>
                          {
                            (this.state.currentPageNumber === 1 || this.state.totalDataItem.length <= 10) ?
                              <ArrowSmLeftIcon className="h-7 w-7 ml-3 inline" style={{ color: "#FB916A" }} /> :
                              <ArrowSmLeftIcon className="h-7 w-7 ml-3 inline" onClick={this.previous} />
                          }
                        </li>
                        <li style={{ display: "inline", marginRight: "3px" }}>
                          {
                            (this.state.totalDataItem.lenght === 0 || this.state.currentPageNumber === this.state.totalPagenumber) ?
                              <ArrowSmRightIcon className="h-7 w-7 ml-2 inline" style={{ color: "#FB916A" }} /> :
                              <ArrowSmRightIcon className="h-7 w-7 ml-2 inline" onClick={this.next} />
                          }
                        </li>
                      </ul> : <img src={loader} alt={loader} style={{ width: "25px", height: "auto" }} />
                  }
                </div> : ''
            } */}
          </div>
        </div>
      </>
    );
  }
}