/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import Modal from 'react-awesome-modal';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers';
import * as Yup from 'yup';
import { baseApi } from '../../../services/constants';
import loader from '../../../assets/img/loader.gif';

const axios = require('axios');

export default function AddSuccessStory(props) {

  const [isSubmit, setIsSubmit] = useState(false);
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
  });

  useEffect(() => {
    function handleResize() {
      setDimensions({
        width: window.innerWidth,
      });
    }
    window.addEventListener('resize', handleResize);
    return (_) => {
      window.removeEventListener('resize', handleResize);
    };
  });

  const closePopup = () => {
    setOnError(false);
    setOnErrorMessage('');
    setSuccessfull(false);
    props.setVisible(false);
    setIsSubmit(true);
  }

  const validationSchema = Yup.object().shape({
    user1: Yup.string()
      .required('Username is required'),
    user2: Yup.string()
      .required('Username is required'),
  });

  const { register, handleSubmit, reset, errors } = useForm({
    resolver: yupResolver(validationSchema)
  });

  const [successfull, setSuccessfull] = useState(false);
  const [onError, setOnError] = useState(false);
  const [onErrorMessage, setOnErrorMessage] = useState('');

  function onSubmit(data) {
    setIsSubmit(true);
    axios({
      method: "post",
      url: `${baseApi}open/successstory`,
      data: data,
      headers: { "Authorization": localStorage.getItem("access_token") }
    }).then(result => {
      if (result.data.status === 'success') {
        setOnError(false)
        setSuccessfull(true);
        reset();
        setTimeout(() => {
          closePopup();
          setSuccessfull(false);
        }, 1000);
      }
      else if (result.data.status === 'failed') {
        setOnErrorMessage(result.data.message)
        setOnError(true);
      }
    })
  }

  return (
    <Modal
      visible={props.visible}
      width={`${dimensions.width < 768 ? '100%' : '30%'}`}
      height={`${dimensions.width < 768 ? '100%' : '50%'}`}
      effect="fadeInLeft"
      onClickAway={() => {
        setOnError(false)
        setOnErrorMessage('')
        setSuccessfull(false);
        props.setVisible(false)
      }}
    >
      <div className="flex relative -top-14 rounded h-auto bg-white overflow-y-scroll">
        <div className="w-9/12 mx-auto">
          <p className="font-bold sm:text-3xl text-lg text-center mt-20 md:mt-14 lg:mt-14 font-medium">
            Add Success Story
          </p>
          <div className="w-full items-center">
            <form onSubmit={handleSubmit(onSubmit)} onReset={reset}>
              <div className="flex items-center mt-4">
                <input
                  ref={register}
                  type="text"
                  className="border focus:outline-none border-gray-300 py-2 lg:px-6 px-2 rounded w-full text-sm" placeholder="First Name *"
                  placeholder="Username"
                  name="user1"
                />
              </div>
              <div className="text-red-500 font-semibold text-md">{errors.user1?.message}</div>
              <div className="flex items-center mt-4">
                <input
                  ref={register}
                  type="text"
                  className="border focus:outline-none border-gray-300 py-2 lg:px-6 px-2 rounded w-full text-sm" placeholder="First Name *"
                  placeholder="Username"
                  name="user2"
                />
              </div>
              <div className="text-red-500 font-semibold text-md">{errors.user2?.message}</div>
              {
                successfull ?
                  <p className="text-center font-bold text-green-900 mt-4 text-base mx-auto font-thin">
                    Data Saved Successfully
                      </p> :
                  null
              }
              {
                onError ?
                  <p className="text-center font-bold text-red-900 mt-4 text-base lg:w-2/5 w-11/12 mx-auto font-thin">
                    {onErrorMessage}
                  </p> :
                  null
              }
              <div className="flex items-center mt-4">
                <button type="submit"
                  className="py-2 px-4 w-full text-center rounded text-white text-sm cursor-pointer" style={{backgroundColor:"#F74300"}}
                > 
                  {
                    isSubmit ?
                    <img src={loader} alt={loader} style={{width:'1.2rem', left:'50%', position:'relative'}}/> :
                    'Submit'  
                  }
                </button>
              </div>
              <div className="flex items-center mt-2">
                <button type="reset" onClick={() => closePopup()}
                  className="py-2 px-4 w-full text-center rounded text-white text-sm cursor-pointer" style={{backgroundColor:"#F74300"}}
                > Close </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
}