/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import rocket from '../../../../../../assets/img/rocLaunch1.png';
import loader from '../../../../../../assets/img/loader.gif';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers';
import * as Yup from 'yup';
import { baseApi } from '../../../../../../services/constants';

const axios = require('axios');

export default function ResetPassword(props) {

  const [username, setUsername] = useState('');
  const [isResponse, setIsResponse] = useState(false);
  const [isResponseStatus, setIsResponseStatus] = useState('');
  const [isResponseMessage, setIsResponseMessage] = useState('');
  const [isSubmit, setIsSubmit] = useState(false);

  useEffect(() => {
    getUsername();
  }, []);

  const getUsername = async () => {
    await setUsername(props.username)
  }
  const closePopup = () => {
    props.closeResetPassword();
  }

  const validationSchema = Yup.object().shape({
    otp: Yup.string()
      .required('OTP is required'),
    password: Yup.string()
      .min(6, 'Password must be at least 6 characters')
      .required('Password is required'),
    confirm_password: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Confirm Password is required'),
  });

  const { register, handleSubmit, reset, errors } = useForm({
    resolver: yupResolver(validationSchema)
  });

  function onSubmit(formdata) {
    setIsSubmit(true);
    let data = {
      username: username.username,
      otp:formdata.otp,
      password:formdata.password,
    }
    axios({
      method: "patch",
      url: `${baseApi}user/setnewpassword`,
      data:data
    })
      .then(result => {
        if(result.data){
          setIsResponse(true);
          setIsResponseStatus(result.data.status);
          setIsResponseMessage(result.data.message);
          setIsSubmit(false);
        }
      })
  }

  return (
    <div className="flex relative -top-4 rounded h-auto bg-white overflow-y-scroll">
      <div className="hidden xl:block" style={{ width: 400 }}>
        <img
          src={rocket}
          loading="lazy"
          alt="rocket"
          className="z-10 relative -left-0.5"
        />
      </div>
      <div className="w-9/12 w-11/12 mx-auto">
        <p className="font-bold sm:text-3xl text-lg text-center mt-20 md:mt-14 lg:mt-14 font-medium">
          Reset Password
        </p>
        <p className="text-center mt-4 text-base lg:w-2/5 w-11/12 mx-auto font-thin">Set new password</p>
        <div>
          <div className="w-full flex-col flex items-center">
            <form onSubmit={handleSubmit(onSubmit)} onReset={reset} className="w-full lg:w-1/2">
              <div className="flex items-center mt-4">
                <input
                  ref={register}
                  type="text"
                  className="border focus:outline-none border-gray-300 py-2 lg:px-6 px-2 rounded w-full text-sm" placeholder="First Name *"
                  placeholder="OTP *"
                  name="otp"
                />
              </div>
              <div className="text-red-500 font-semibold text-md">{errors.otp?.message}</div>
              <div className="flex items-center mt-4">
                <input
                  ref={register}
                  type="password"
                  className="border focus:outline-none border-gray-300 py-2 lg:px-6 px-2 rounded w-full text-sm" placeholder="First Name *"
                  placeholder="Password *"
                  name="password"
                />
              </div>
              <div className="text-red-500 font-semibold text-md">{errors.password?.message}</div>
              <div className="flex items-center mt-4">
                <input
                  ref={register}
                  type="password"
                  className="border focus:outline-none border-gray-300 py-2 lg:px-6 px-2 rounded w-full text-sm" placeholder="First Name *"
                  placeholder="Confirm Password *"
                  name="confirm_password"
                />
              </div>
              <div className="text-red-500 font-semibold text-md">{errors.confirm_password?.message}</div>
              {
                isResponse ?
                <div className={`${isResponseStatus === 'success' ? 'text-green-500' : 'text-red-500'} font-semibold text-md mt-2`}>
                  {isResponseMessage}
                </div> : 
                null
              }
              <div className="flex items-center mt-4">
                <button type="submit"
                  className="py-2 px-4 w-full text-center rounded text-white text-sm cursor-pointer" style={{backgroundColor:"#F74300"}}
                > 
                  {
                    isSubmit ?
                    <img src={loader} alt={loader} style={{width:'1.2rem', left:'50%', position:'relative'}}/> :
                    'Reset Password'  
                  } 
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}