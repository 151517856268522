/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from 'react';
import { Link, NavLink, useHistory } from 'react-router-dom';
import logo from '../../../../../assets/img/logo.png';
import burger from '../../../../../assets/img/burger.svg';
import close from '../../../../../assets/img/xWhite.svg';
import Modal from 'react-awesome-modal';
import SignUp from './SignUp';
import Login from './Login';
import { isLogin } from '../../../../../services/auth';

export default function Navbar() {

  const [showSignUp, setShowSignUp] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [visibleSidebar, setVisibleSidebar] = useState(false);

  let history = useHistory();

  const logOut = () => {
    localStorage.removeItem("id");
    localStorage.removeItem("first_name");
    localStorage.removeItem("last_name");
    localStorage.removeItem("phone");
    localStorage.removeItem("role");
    localStorage.removeItem("access_token");
    localStorage.removeItem("username");
    history.push('/');
    window.location.reload();
  }

  return (
    <nav className="navbarCont py-3 lg:px-32 px-8 flex items-center justify-between w-full sticky z-30 top-0"
      style={{ backgroundColor: "#F74300" }}>
      <div className="flex items-center cursor-pointer">
        <Link to="/home">
          <img src={logo} loading="lazy" alt="StategyConnectLogo" className="h-10 w-10 cursor-pointer" />
        </Link>
        <Link to="/home">
          <p className="nunito lg:flex text-white hidden text-xl font-bold ml-1.5 relative top-0.5 cursor-pointer">
            Salunke Maratha
          </p>
        </Link>
      </div>
      <ul className="items-center xl:flex hidden justify-between w-2/6 ">
        <li className="nav-item no-underline text-base text-white hover:text-gray-500 cursor-pointer">
          <NavLink to="/home">Home</NavLink>
        </li>
        <li className="nav-item no-underline text-base text-white hover:text-gray-500 cursor-pointer">
          <NavLink to="/members">Members</NavLink>
        </li>
        <li className="nav-item no-underline text-base text-white hover:text-gray-500 cursor-pointer">
          <NavLink to="/success-story">Success Story</NavLink>
        </li>
        <li className="nav-item no-underline text-base text-white hover:text-gray-500 cursor-pointer">
          <NavLink to="/contact">Contact Us</NavLink>
        </li>
      </ul>

      <div className="flex items-center">
        {
          !isLogin() ?
            <Link to="/signup">
              <p style={{ backgroundColor: "#FB916A" }}
                className="ml-3.5 py-2 px-9 hidden lg:flex rounded purple text-white text-sm cursor-pointer" 
              > Register </p>
            </Link> :
            null
        }
        {
          !isLogin() ?
            <p
              onClick={() => { setShowLogin(true); }}
              className="ml-3.5 py-2 px-9 hidden lg:flex rounded purple text-white text-sm cursor-pointer" style={{ backgroundColor: "#FB916A" }}
            > Login </p> :
            null
        }
        {
          isLogin() ?
            <Link to="/profile">
              <p
                className="ml-3.5 py-2 px-9 hidden lg:flex rounded purple text-white text-sm cursor-pointer" style={{ backgroundColor: "#FB916A" }}
              >  Profile </p> </Link> :
            null
        }
        {
          isLogin() ?
            <p
              onClick={logOut}
              className="ml-3.5 py-2 px-9 hidden lg:flex rounded purple text-white text-sm cursor-pointer" style={{ backgroundColor: "#FB916A" }}
            > Logout </p> :
            null
        }
        <img
          onClick={() => setVisibleSidebar(true)}
          src={burger}
          alt="burger"
          className="xl:hidden flex ml-4 cursor-pointer"
        />
        <div className={`${visibleSidebar ? 'block' : 'hidden'}`}>
          <Modal
            visible={visibleSidebar}
            width="100%"
            height="100%"
            effect="fadeInLeft"
            onClickAway={() => setVisibleSidebar(false)}
          >
            <div className="p-4 xl:hidden bg-black h-full overflow-y-scroll">
              <div className="flex items-center justify-between ">
                <div className="flex items-center">
                  <img
                    src={logo}
                    alt="logo"
                    className="h-6 w-6 cursor-pointer"
                  />
                  <p className="nunito flex text-white font-bold text-lg ml-1.5 relative top-0.5 cursor-pointer">
                    Salunke Maratha
                  </p>
                </div>
                <img
                  src={close}
                  alt="close"
                  className=""
                  onClick={() => {
                    setVisibleSidebar(false);
                  }}
                />
              </div>

              <ul className="pl-4 mt-10">
                <li onClick={() => setVisibleSidebar(false)} className="text-lg font-medium text-white mt-4">
                  <Link to="/home">Home</Link>
                </li>
                <li onClick={() => setVisibleSidebar(false)} className="text-lg font-medium text-white mt-4">
                  <Link to="/members">Members</Link>
                </li>
                <li onClick={() => setVisibleSidebar(false)} className="text-lg font-medium text-white mt-4">
                  <Link to="/success-story">Success Story</Link>
                </li>
                <li onClick={() => setVisibleSidebar(false)} className="text-lg font-medium text-white mt-4">
                  <Link to="/contact">Contact Us</Link>
                </li>
              </ul>

              <div className="mt-8 pt-8 border-grey-top w-full">
                {
                  !isLogin() ?
                  <Link to="/signup">
                    <p className="py-2 px-4 purple mx-auto w-44 text-center text-white rounded mt-4" style={{ backgroundColor: "#F74300" }}
                      onClick={() => { setShowSignUp(true); }}
                    > Register </p> 
                  </Link> :
                  null
                }
                {
                  !isLogin() ?
                    <p className="py-2 px-4 purple mx-auto w-44 text-center text-white rounded mt-4" style={{ backgroundColor: "#F74300" }}
                      onClick={() => { setShowLogin(true); }}
                    > Login </p> :
                    null
                }
                {
                  isLogin() ?
                    <Link to="/profile">
                      <p className="py-2 px-4 purple mx-auto w-44 text-center text-white rounded mt-4" style={{ backgroundColor: "#F74300" }}
                      > Profile </p> </Link> :
                    null
                }
                {
                  isLogin() ?
                    <p className="py-2 px-4 purple mx-auto w-44 text-center text-white rounded mt-4" style={{ backgroundColor: "#F74300" }}
                      onClick={logOut}
                    > Logout </p> :
                    null
                }
              </div>
            </div>
          </Modal>
        </div>

        <div className={`${showSignUp ? 'block' : 'hidden'}`}>
          <SignUp visible={showSignUp} setVisible={setShowSignUp} />
        </div>
        <div className={`${showLogin ? 'block' : 'hidden'}`}>
          <Login visible={showLogin} setVisible={setShowLogin} />
        </div>
      </div>
    </nav>
  );
}
