import React, { useState, useEffect } from 'react';
import loadable from '@loadable/component';
import Navbar from '../Home/SmallHome/components/Navbar';
import { Link } from 'react-router-dom';
import { baseApi, height, weight, education_type, complexition, diet, maritial_status, cast, rashi, 
  expectation_age_difference, occupation_type } from '../../services/constants';
import { BackspaceIcon } from '@heroicons/react/solid';
import axios from 'axios';
import Popup from './Popup';
import loader from '../../assets/img/loader.gif';

const Footer = loadable(() => import('../Home/SmallHome/components/Footer'));

export default function EditProfile() {
  
  const [isSubmit, setIsSubmit] = useState(false);
  const [isProfilePictureUploading, setIsProfilePictureUploading] = useState(false);
  const [dateOfBirth, setDateOfBirth] = useState();
  const [isSuccess, setIsSuccess] = useState(false);
  const [user, setUser] = useState({});
  const [isAbout, setIsAbout] = useState(true);
  const [isBio, setIsBio] = useState(false);
  const [isLocation, setIsLocation] = useState(false);
  const [isEducationAndJob, setIsEducationAndJob] = useState(false);
  const [isParent, setIsParent] = useState(false);
  const [isMiscellaneous, setIsMiscellaneous] = useState(false);
  const [isPartner, setIsPartner] = useState(false);

  const buttons = [
    {
      button_title: "About",
      button_classes: `{mb-0 md:mb-2 lg:mb-2 inline-flex justify-center py-2 px-4 m-2 lg:ml-0 border border-transparent shadow-sm text-sm font-medium rounded-md text-white edit-profile-button focus:outline-none ${isAbout ? 'ring-2 ring-offset-2 ring-indigo-500' : ''} focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500}`
    },
    {
      button_title: "Bio",
      button_classes: `{mb-0 md:mb-2 lg:mb-2 inline-flex justify-center py-2 px-4 m-2 lg:ml-0 border border-transparent shadow-sm text-sm font-medium rounded-md text-white edit-profile-button focus:outline-none ${isBio ? 'ring-2 ring-offset-2 ring-indigo-500' : ''} focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500}`
    },
    {
      button_title: "Location",
      button_classes: `{mb-0 md:mb-2 lg:mb-2 inline-flex justify-center py-2 px-4 m-2 lg:ml-0 border border-transparent shadow-sm text-sm font-medium rounded-md text-white edit-profile-button focus:outline-none ${isLocation ? 'ring-2 ring-offset-2 ring-indigo-500' : ''} focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500}`
    },
    {
      button_title: "Education and Job",
      button_classes: `{mb-0 md:mb-2 lg:mb-2 inline-flex justify-center py-2 px-4 m-2 lg:ml-0 border border-transparent shadow-sm text-sm font-medium rounded-md text-white edit-profile-button focus:outline-none ${isEducationAndJob ? 'ring-2 ring-offset-2 ring-indigo-500' : ''} focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500}`
    },
    {
      button_title: "Parent",
      button_classes: `{mb-0 md:mb-2 lg:mb-2 inline-flex justify-center py-2 px-4 m-2 lg:ml-0 border border-transparent shadow-sm text-sm font-medium rounded-md text-white edit-profile-button focus:outline-none ${isParent ? 'ring-2 ring-offset-2 ring-indigo-500' : ''} focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500}`
    },
    {
      button_title: "Miscellaneous",
      button_classes: `{mb-0 md:mb-2 lg:mb-2 inline-flex justify-center py-2 px-4 m-2 lg:ml-0 border border-transparent shadow-sm text-sm font-medium rounded-md text-white edit-profile-button focus:outline-none ${isMiscellaneous ? 'ring-2 ring-offset-2 ring-indigo-500' : ''} focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500}`
    },
    {
      button_title: "Partner's Criteria",
      button_classes: `{mb-0 md:mb-2 lg:mb-2 inline-flex justify-center py-2 px-4 m-2 lg:ml-0 border border-transparent shadow-sm text-sm font-medium rounded-md text-white edit-profile-button focus:outline-none ${isPartner ? 'ring-2 ring-offset-2 ring-indigo-500' : ''} focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500}`
    }
  ]

  useEffect(() => {
    document.title = "Edit Profile"
    getUser();
  }, [])

  function getUser() {
    axios({
      method: "get",
      url: `${baseApi}user/userprofile`,
      headers: { Authorization: localStorage.getItem("access_token") }
    })
      .then(res => {
        setUser(res.data.user);
        let dob = new Date(res.data.user.dob);
        let year = `${dob.getFullYear()}`;
        let month = dob.getMonth()+1 < 10 ? `0${dob.getMonth()+1}` : `${dob.getMonth()+1}`
        let day = dob.getDate() < 10 ? `0${dob.getDate()}` : `${dob.getDate()}`
        let formatDate = `${year}-${month}-${day}`
        setDateOfBirth(formatDate)
      })
  }

  function onEditChange(name) {
    if (name === "About") {
      setIsAbout(true);
      setIsBio(false);
      setIsLocation(false);
      setIsEducationAndJob(false);
      setIsParent(false);
      setIsMiscellaneous(false);
      setIsPartner(false);
    } else if (name === "Bio") {
      setIsAbout(false);
      setIsBio(true);
      setIsLocation(false);
      setIsEducationAndJob(false);
      setIsParent(false);
      setIsMiscellaneous(false);
      setIsPartner(false);
    } else if (name === "Location") {
      setIsAbout(false);
      setIsBio(false);
      setIsLocation(true);
      setIsEducationAndJob(false);
      setIsParent(false);
      setIsMiscellaneous(false);
      setIsPartner(false);
    } else if (name === "Education and Job") {
      setIsAbout(false);
      setIsBio(false);
      setIsLocation(false);
      setIsEducationAndJob(true);
      setIsParent(false);
      setIsMiscellaneous(false);
      setIsPartner(false);
    } else if (name === "Parent") {
      setIsAbout(false);
      setIsBio(false);
      setIsLocation(false);
      setIsEducationAndJob(false);
      setIsParent(true);
      setIsMiscellaneous(false);
      setIsPartner(false);
    } else if (name === "Miscellaneous") {
      setIsAbout(false);
      setIsBio(false);
      setIsLocation(false);
      setIsEducationAndJob(false);
      setIsParent(false);
      setIsMiscellaneous(true);
      setIsPartner(false);
    } else if (name === "Partner's Criteria") {
      setIsAbout(false);
      setIsBio(false);
      setIsLocation(false);
      setIsEducationAndJob(false);
      setIsParent(false);
      setIsMiscellaneous(false);
      setIsPartner(true);
    }
  }

  function handleOnChange(event) {
    setUser({ ...user, [event.target.name]: event.target.value })
  }

  function checkboxOnChange(event) {
    setUser({ ...user, [event.target.name]: event.target.checked })
  }

  function onDateChange(event){
    let dob = new Date(event.target.value);
    let year = `${dob.getFullYear()}`;
    let month = dob.getMonth()+1 < 10 ? `0${dob.getMonth()+1}` : `${dob.getMonth()+1}`
    let day = dob.getDate() < 10 ? `0${dob.getDate()}` : `${dob.getDate()}`
    let formatDate = `${year}-${month}-${day}`
    setDateOfBirth(formatDate)
    setUser({ ...user, [event.target.name]: event.target.value })
  }

  function onImageChange(event){
    setIsProfilePictureUploading(true);
    const formData = new FormData();
    formData.append('avatar', event.target.files[0]);
    axios({
      method: "post",
      url: `${baseApi}user/uploadprofilepicture`,
      data: formData,
      headers: { Authorization: localStorage.getItem("access_token") }
    })
      .then(res => {
        if(res.data.status === "success"){
          setUser({ ...user, avatar: res.data.url })
          setIsProfilePictureUploading(false);
          setIsSuccess(true);
          setTimeout(() => {setIsSuccess(false)}, 5000)
        }
        else if(res.data.status === "failed"){
          alert(res.data.message)
          setIsProfilePictureUploading(false);
        }
      })
  }

  function editUser(e) {
    e.preventDefault();
    setIsSubmit(true);

    let data = [];
    for (const [key, value] of Object.entries(user)) {
      if (key !== "_id") {
        data.push({ key: key, value: value });
      }
    }

    axios({
      method: "patch",
      url: `${baseApi}user/edituserprofile`,
      data: data,
      headers: { Authorization: localStorage.getItem("access_token") }
    })
      .then(res => {
        if(res.data.status === "success"){
          setIsSubmit(false);
          setIsSuccess(true);
          setTimeout(() => {setIsSuccess(false)}, 2000)
        }
      })
  }

  return (
    <>
      <Navbar />
      <div className="mt-4 pb-10 w-10/12 mx-auto relative">
        {
          isSuccess ?
          <Popup/>:
          null
        }
        <p className="text-left text-4xl xl:text-4xl mb-8 font-bold"> Edit </p>
        <Link to="/profile" style={{color:'#fff'}}>
          <span className="absolute right-0 top-0">
            <button className="inline-flex items-center px-4 py-2 border rounded-md shadow-sm text-sm font-medium text-white-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" style={{backgroundColor:"#F74300"}}>
              <BackspaceIcon className="h-5 w-5 mr-3" /> Back
            </button>
          </span>
        </Link>
        <div className="text-left mb-4">
          {
            buttons.map((item, index) => {
              return (
                <button onClick={() => onEditChange(item.button_title)} className={item.button_classes}>
                  {item.button_title}
                </button>)
            })
          }
        </div>

        {/* About */}
        {
          isAbout ?
            <div>
              <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-1">
                  <div className="px-4 sm:px-0">
                    <h3 className="text-lg font-bold leading-6 text-gray-900">About Me</h3>
                    <p className="mt-1 text-sm text-gray-600">
                      This information will be displayed publicly so be careful what you share.
                    </p>
                  </div>
                </div>
                <div className="mt-5 md:mt-0 md:col-span-2">
                  <form>
                    <div className="shadow sm:rounded-md sm:overflow-hidden">
                      <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                        <div className="grid grid-cols-6 gap-6">
                          <div className="col-span-6 sm:col-span-6">
                            <label for="first_name" className="block text-sm font-medium text-gray-700">First name</label>
                            <input type="text" onChange={(event) => handleOnChange(event)} value={user.first_name} onChange={(event) => handleOnChange(event)} name="first_name" id="first_name" className="mt-1 p-2 border-2 ring-indigo-200 focus:ring-indigo-500 block w-full shadow-sm sm:text-sm rounded-md" />
                          </div>
                          <div className="col-span-6 sm:col-span-6">
                            <label for="middle_name" className="block text-sm font-medium text-gray-700">Middle name</label>
                            <input type="text" onChange={(event) => handleOnChange(event)} value={user.middle_name} onChange={(event) => handleOnChange(event)} name="middle_name" id="middle_name" className="mt-1 p-2 border-2 ring-indigo-200 focus:ring-indigo-500 block w-full shadow-sm sm:text-sm rounded-md" />
                          </div>
                          <div className="col-span-6 sm:col-span-6">
                            <label for="last_name" className="block text-sm font-medium text-gray-700">Last name</label>
                            <input type="text" onChange={(event) => handleOnChange(event)} value={user.last_name} name="last_name" id="last_name" className="mt-1 p-2 border-2 ring-indigo-200 focus:ring-indigo-500 block w-full shadow-sm sm:text-sm rounded-md" />
                          </div>
                          <div className="col-span-6 sm:col-span-3 sm:col-span-3">
                            <label for="email" className="block text-sm font-medium text-gray-700">Email</label>
                            <input type="text" onChange={(event) => handleOnChange(event)} value={user.email} name="email" id="email" className="mt-1 p-2 border-2 ring-indigo-200 focus:ring-indigo-500 block w-full shadow-sm sm:text-sm rounded-md" />
                          </div>
                          <div className="col-span-6 sm:col-span-3 sm:col-span-3">
                            <label for="phone" className="block text-sm font-medium text-gray-700">Mobile</label>
                            <input type="text" onChange={(event) => handleOnChange(event)} value={user.phone} name="phone" id="phone" className="mt-1 p-2 ring-indigo-200 focus:ring-indigo-500 block w-full shadow-sm sm:text-sm rounded-md" />
                          </div>
                          <div className="col-span-6 sm:col-span-3 sm:col-span-3">
                            <label for="gender" className="mr-3 block text-sm font-medium text-gray-700">
                              Gender
                            </label>
                            <div className="flex items-center mt-6">
                              <input id="male" name="gender" value="Male" onChange={(event) => handleOnChange(event)} checked={user.gender === "Male"} type="radio" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300" />
                              <label for="male" className="ml-3 block text-sm font-medium text-gray-700">
                                Male
                              </label>
                              <input id="female" name="gender" value="Female" onChange={(event) => handleOnChange(event)} checked={user.gender === "Female"} type="radio" className="ml-3 focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300" />
                              <label for="female" className="ml-3 block text-sm font-medium text-gray-700">
                                Female
                              </label>
                            </div>
                          </div>
                          <div className="col-span-6 sm:col-span-3 lg:col-span-3 mt-8 md:mt-0">
                            <label for="dob" className="block text-sm font-medium text-gray-700">Date of Birth</label>
                            <input type="date" value={dateOfBirth} onChange={(event) => onDateChange(event)} name="dob" id="dob" className="mt-1 p-2 ring-indigo-200 focus:ring-indigo-500 block w-full shadow-sm sm:text-sm border-gray-800 rounded-md" />
                          </div>
                          <div className="col-span-6 sm:col-span-3 lg:col-span-3">
                            <label className="block text-sm font-medium text-gray-700">
                              Photo
                            </label>
                            <div className="mt-1 flex items-center">
                              {
                                user.avatar ?
                                  <img
                                    className="inline-block h-12 w-12 rounded-full ring-2 ring-white"
                                    src={user.avatar}
                                    alt={user.first_name + user.last_name}
                                  /> :
                                  <span className="inline-block h-12 w-12 rounded-full overflow-hidden bg-gray-100">
                                    <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                      <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                    </svg>
                                  </span>
                              }
                              <label className="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                <span>Change </span>
                                <input id="file-upload" onChange={(event) => onImageChange(event)} name="acatar" type="file" className="sr-only" />
                              </label>
                              {
                                isProfilePictureUploading ?                    
                                <span className="ml-4 text-md font-medium text-gray-900">Uploading...</span>
                                : null
                              }
                            </div>
                          </div>
                        </div>
                        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                          {
                            isProfilePictureUploading ?
                            <button disabled className="inline-flex justify-center py-2 px-4 border border-transparent bg-gray shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                              Save
                            </button> : 
                            <button type="submit" onClick={(e) => editUser(e)} className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" style={{backgroundColor:"#F74300"}}>
                              {
                                isSubmit ?
                                <img src={loader} alt={loader} style={{width:'1.3rem', margin:"0 5px", position:'relative'}}/> : 
                                'Save'  
                              }
                            </button> 
                          }
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            : null
        }

        {/* Bio */}
        {
          isBio ?
            <div>
              <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-1">
                  <div className="px-4 sm:px-0">
                    <h3 className="text-lg font-bold leading-6 text-gray-900">Bio</h3>
                    <p className="mt-1 text-sm text-gray-600">
                      This information will be displayed how you look and how is your personality.
                </p>
                  </div>
                </div>
                <div className="mt-5 md:mt-0 md:col-span-2">
                  <form>
                    <div className="shadow sm:rounded-md sm:overflow-hidden">
                      <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                        <div className="grid grid-cols-6 gap-6">
                          <div className="col-span-6 md:col-span-3 lg:col-span-3">
                            <label for="height" className="block text-sm font-medium text-gray-700">Height</label>
                            <select id="height" name="height" value={user.height} onChange={(event) => handleOnChange(event)} className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                              <option>Select</option>
                              {
                                height.map((item, index) => {
                                  return <option key={index} value={item}>{item} Feet</option>
                                }
                                )
                              }
                            </select>
                          </div>
                          <div className="col-span-6 md:col-span-3 lg:col-span-3">
                            <label for="education_type" className="block text-sm font-medium text-gray-700">Weight</label>
                            <select id="weight" name="weight" value={user.weight} onChange={(event) => handleOnChange(event)} className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                              <option>Select</option>
                              {
                                weight.map((item, index) => {
                                  return <option key={index} value={item}>{item}</option>
                                }
                                )
                              }
                            </select>
                          </div>
                          <div className="col-span-6 md:col-span-3 lg:col-span-3">
                            <label for="complextion" className="block text-sm font-medium text-gray-700">Complextion</label>
                            <select id="complextion" name="complextion" value={user.complextion} onChange={(event) => handleOnChange(event)} className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                              <option>Select</option>
                              {
                                complexition.map((item, index) => {
                                  return <option key={index} value={item}>{item}</option>
                                })
                              }
                            </select>
                          </div>
                          <div className="col-span-6 md:col-span-3 lg:col-span-3">
                            <label for="diet" className="block text-sm font-medium text-gray-700">Diet</label>
                            <select id="diet" name="diet" value={user.diet} onChange={(event) => handleOnChange(event)} className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                              <option>Select</option>
                              {
                                diet.map((item, index) => {
                                  return <option key={index} value={item}>{item}</option>
                                })
                              }
                            </select>
                          </div>
                          <div className="col-span-6 md:col-span-3 lg:col-span-3">
                            <div className="flex items-start">
                              <div className="flex items-center h-5">
                                <input id="physical_disablities" checked={user.physical_disablities} onChange={(event) => checkboxOnChange(event)} name="physical_disablities" type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                              </div>
                              <div className="ml-3 text-sm">
                                <label for="physical_disablities" className="font-medium text-gray-700">Physical Disablities</label>
                              </div>
                            </div>
                          </div>
                          <div className="col-span-6 md:col-span-3 lg:col-span-3">
                            <div className="flex items-start">
                              <div className="flex items-center h-5">
                                <input id="spectacles" checked={user.spectacles} onChange={(event) => checkboxOnChange(event)} name="spectacles" type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                              </div>
                              <div className="ml-3 text-sm">
                                <label for="spectacles" className="font-medium text-gray-700">Spectacles</label>
                              </div>
                            </div>
                          </div>
                          <div className="col-span-6 sm:col-span-3 sm:col-span-3">
                            <label for="blood_group" className="block text-sm font-medium text-gray-700">Blood Group</label>
                            <input type="text" value={user.blood_group} onChange={(event) => handleOnChange(event)} name="blood_group" id="blood_group" className="mt-1 p-2 border-2 ring-indigo-200 focus:ring-indigo-500 block w-full shadow-sm sm:text-sm rounded-md" />
                          </div>
                        </div>
                      </div>
                      <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                        <button type="submit" onClick={(e) => editUser(e)} className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" style={{backgroundColor:"#F74300"}}>
                          {
                            isSubmit ?
                            <img src={loader} alt={loader} style={{width:'1.3rem', margin:"0 5px"}}/> :
                            'Save'  
                          }
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div> :
            null
        }

        {/* Location */}
        {
          isLocation ?
            <div className="mt-10 sm:mt-0">
              <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-1">
                  <div className="px-4 sm:px-0">
                    <h3 className="text-lg font-bold leading-6 text-gray-900">Location Information</h3>
                    <p className="mt-1 text-sm text-gray-600">
                      Use a permanent address where you can receive mail.
                    </p>
                  </div>
                </div>
                <div className="mt-5 md:mt-0 md:col-span-2">
                  <form>
                    <div className="shadow overflow-hidden sm:rounded-md">
                      <div className="px-4 py-5 bg-white sm:p-6">
                        <div className="grid grid-cols-6 gap-6">
                          <div className="col-span-6">
                            <label for="address" className="block text-sm font-medium text-gray-700">
                              Address
                            </label>
                            <div className="mt-1">
                              <textarea id="address" name="address" value={user.address} onChange={(event) => handleOnChange(event)} rows="3" className="border-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 p-2 block w-full sm:text-sm border-gray-300 rounded-md"></textarea>
                            </div>
                          </div>

                          <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                            <label for="state" className="block text-sm font-medium text-gray-700">State</label>
                            <input type="text" value={user.state} onChange={(event) => handleOnChange(event)} name="state" id="state" className="mt-1 p-2 focus:ring-indigo-500 border-2 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                          </div>

                          <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                            <label for="district" className="block text-sm font-medium text-gray-700">District</label>
                            <input type="text" value={user.district} onChange={(event) => handleOnChange(event)} name="district" id="district" className="mt-1 p-2 focus:ring-indigo-500 border-2 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                          </div>

                          <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                            <label for="home_town" className="block text-sm font-medium text-gray-700">Home Town</label>
                            <input type="text" value={user.home_town} onChange={(event) => handleOnChange(event)} name="home_town" id="home_town" className="mt-1 p-2 focus:ring-indigo-500 border-2 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                          </div>

                          <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                            <label for="current_city" className="block text-sm font-medium text-gray-700">Current City</label>
                            <input type="text" value={user.current_city} onChange={(event) => handleOnChange(event)} name="current_city" id="current_city" className="mt-1 p-2 focus:ring-indigo-500 border-2 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                          </div>

                          <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                            <label for="postal_code" className="block text-sm font-medium text-gray-700">ZIP / Pincode</label>
                            <input type="text" value={user.postal_code} onChange={(event) => handleOnChange(event)} name="postal_code" id="postal_code" className="mt-1 p-2 focus:ring-indigo-500 border-2 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                          </div>
                        </div>
                      </div>
                      <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                        <button type="submit" onClick={(e) => editUser(e)} className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" style={{backgroundColor:"#F74300"}}>
                          {
                            isSubmit ?
                            <img src={loader} alt={loader} style={{width:'1.3rem', margin:"0 5px"}}/> :
                            'Save'  
                          }
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div> :
            null
        }

        {/* Education and Job Information */}
        {
          isEducationAndJob ?
            <div className="mt-10 sm:mt-0">
              <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-1">
                  <div className="px-4 sm:px-0">
                    <h3 className="text-lg font-bold leading-6 text-gray-900">Education and Job Information</h3>
                    <p className="mt-1 text-sm text-gray-600">
                      Show yourself as literate, educational capabilities and what you do.
                    </p>
                  </div>
                </div>
                <div className="mt-5 md:mt-0 md:col-span-2">
                  <form>
                    <div className="shadow overflow-hidden sm:rounded-md">
                      <div className="px-4 py-5 bg-white sm:p-6">
                        <div className="grid grid-cols-6 gap-6">
                          <div className="col-span-6 md:col-span-3 lg:col-span-3">
                            <label for="education_type" className="block text-sm font-medium text-gray-700">Education Type</label>
                            <select id="education_type" name="education_type" value={user.education_type} onChange={(event) => handleOnChange(event)} className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                              <option>Select</option>
                              {
                                education_type.map((item, index) => {
                                  return <option key={index} value={item}>{item}</option>
                                }
                                )
                              }
                            </select>
                          </div>

                          <div className="col-span-6 sm:col-span-3 lg:col-span-3">
                            <label for="education_title" className="block text-sm font-medium text-gray-700">Education Title</label>
                            <input type="text" value={user.education_title} onChange={(event) => handleOnChange(event)} name="education_title" id="education_title" className="mt-1 p-2 focus:ring-indigo-500 border-2 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                          </div>

                          <div className="col-span-6 md:col-span-3 lg:col-span-3">
                            <label for="occupation_type" className="block text-sm font-medium text-gray-700">Occupation Type</label>
                            <select id="occupation_type" name="occupation_type" value={user.occupation_type} onChange={(event) => handleOnChange(event)} className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                              <option>Select</option>
                              {
                                occupation_type.map((item, index) => {
                                  return <option key={index} value={item}>{item}</option>
                                }
                                )
                              }
                            </select>
                          </div>

                          <div className="col-span-6 sm:col-span-3 lg:col-span-3">
                            <label for="occupation_title" className="block text-sm font-medium text-gray-700">Occupation Title</label>
                            <input type="text" value={user.occupation_title} onChange={(event) => handleOnChange(event)} name="occupation_title" id="occupation_title" className="mt-1 p-2 focus:ring-indigo-500 border-2 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                          </div>

                          <div className="col-span-6">
                            <label for="occupation_description" className="block text-sm font-medium text-gray-700">
                              Occupation Description
                            </label>
                            <div className="mt-1">
                              <textarea id="occupation_description" name="occupation_description" value={user.occupation_description} onChange={(event) => handleOnChange(event)} rows="3" className="border-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 p-2 block w-full sm:text-sm border-gray-300 rounded-md"></textarea>
                            </div>
                          </div>

                          <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                            <label for="salary" className="block text-sm font-medium text-gray-700">Salary</label>
                            <input type="text" value={user.salary} onChange={(event) => handleOnChange(event)} name="salary" id="salary" className="mt-1 p-2 focus:ring-indigo-500 border-2 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                          </div>

                        </div>
                      </div>
                      <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                        <button type="submit" onClick={(e) => editUser(e)} className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" style={{backgroundColor:"#F74300"}}>
                          {
                            isSubmit ?
                            <img src={loader} alt={loader} style={{width:'1.3rem', margin:"0 5px"}}/> :
                            'Save'  
                          }
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div> :
            null
        }

        {/* Parent's Information */}
        {
          isParent ?
            <div className="mt-10 sm:mt-0">
              <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-1">
                  <div className="px-4 sm:px-0">
                    <h3 className="text-lg font-bold leading-6 text-gray-900">Parent's Information</h3>
                    <p className="mt-1 text-sm text-gray-600">
                      Tell about your family and relatives.
                </p>
                  </div>
                </div>
                <div className="mt-5 md:mt-0 md:col-span-2">
                  <form>
                    <div className="shadow overflow-hidden sm:rounded-md">
                      <div className="px-4 py-5 bg-white sm:p-6">
                        <div className="grid grid-cols-6 gap-6">
                          <div className="col-span-6 md:col-span-3 lg:col-span-3">
                            <div className="flex items-start">
                              <div className="flex items-center h-5">
                                <input id="father" value={user.father} onChange={(event) => checkboxOnChange(event)} name="father" type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                              </div>
                              <div className="ml-3 text-sm">
                                <label for="father" className="font-medium text-gray-700">Father</label>
                              </div>
                            </div>
                          </div>

                          <div className="col-span-6 md:col-span-3 lg:col-span-3">
                            <div className="flex items-start">
                              <div className="flex items-center h-5">
                                <input id="mother" value={user.mother} onChange={(event) => checkboxOnChange(event)} name="mother" type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                              </div>
                              <div className="ml-3 text-sm">
                                <label for="mother" className="font-medium text-gray-700">Mother</label>
                              </div>
                            </div>
                          </div>

                          <div className="col-span-6 sm:col-span-3 lg:col-span-3">
                            <label for="middle_name" className="block text-sm font-medium text-gray-700">Father's Name</label>
                            <input type="text" value={user.middle_name} onChange={(event) => handleOnChange(event)} name="middle_name" id="middle_name" className="mt-1 p-2 border-2 focus:ring-indigo-500 border-2 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                          </div>

                          <div className="col-span-6 sm:col-span-3 lg:col-span-3">
                            <label for="mother_name" className="block text-sm font-medium text-gray-700">Mother's Name</label>
                            <input type="text" value={user.mother_name} onChange={(event) => handleOnChange(event)} name="mother_name" id="mother_name" className="mt-1 p-2 border-2 focus:ring-indigo-500 border-2 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                          </div>

                          <div className="col-span-6 md:col-span-3 lg:col-span-3">
                            <label for="brother" className="block text-sm font-medium text-gray-700">Brothers</label>
                            <select id="brother" name="brother" value={user.brother} onChange={(event) => handleOnChange(event)} className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                              <option>Select</option>
                              {
                                [...Array(10)].map((e, i) => {
                                  return <option key={i} value={i}>{i}</option>
                                }
                                )
                              }
                            </select>
                          </div>

                          <div className="col-span-6 md:col-span-3 lg:col-span-3">
                            <label for="sister" className="block text-sm font-medium text-gray-700">Sisters</label>
                            <select id="sister" name="sister" value={user.sister} onChange={(event) => handleOnChange(event)} className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                              <option>Select</option>
                              {
                                [...Array(10)].map((e, i) => {

                                  return <option key={i} value={i}>{i}</option>
                                })
                              }
                            </select>
                          </div>

                          <div className="col-span-6 sm:col-span-3 lg:col-span-3">
                            <label for="mama_last_name" className="block text-sm font-medium text-gray-700">Mama's Last Name</label>
                            <input type="text" value={user.mama_last_name} onChange={(event) => handleOnChange(event)} name="mama_last_name" id="mama_last_name" className="mt-1 p-2 focus:ring-indigo-500 border-2 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                          </div>

                          <div className="col-span-6 sm:col-span-3 lg:col-span-3">
                            <label for="parent_resident_city" className="block text-sm font-medium text-gray-700">Parent Resident City</label>
                            <input type="text" value={user.parent_resident_city} onChange={(event) => handleOnChange(event)} name="parent_resident_city" id="parent_resident_city" className="mt-1 p-2 focus:ring-indigo-500 border-2 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                          </div>

                          <div className="col-span-6">
                            <label for="parent_occupation" className="block text-sm font-medium text-gray-700">
                              Parent's Occupation Description
                        </label>
                            <div className="mt-1">
                              <textarea id="parent_occupation" name="parent_occupation" value={user.parent_occupation} onChange={(event) => handleOnChange(event)} rows="3" className="border-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 p-2 block w-full sm:text-sm border-gray-300 rounded-md"></textarea>
                            </div>
                          </div>

                          <div className="col-span-6">
                            <label for="family_welth" className="block text-sm font-medium text-gray-700">
                              Family Welth
                        </label>
                            <div className="mt-1">
                              <textarea id="family_welth" name="family_welth" value={user.family_welth} onChange={(event) => handleOnChange(event)} rows="3" className="border-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 p-2 block w-full sm:text-sm border-gray-300 rounded-md"></textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                        <button type="submit" onClick={(e) => editUser(e)} className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" style={{backgroundColor:"#F74300"}}>
                          {
                            isSubmit ?
                            <img src={loader} alt={loader} style={{width:'1.3rem', margin:"0 5px"}}/> :
                            'Save'  
                          }
                    </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div> :
            null
        }

        {/* Miscellaneous Information */}
        {
          isMiscellaneous ?
            <div className="mt-10 sm:mt-0">
              <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-1">
                  <div className="px-4 sm:px-0">
                    <h3 className="text-lg font-bold leading-6 text-gray-900">Miscellaneous Information</h3>
                    <p className="mt-1 text-sm text-gray-600">
                      Tell other information about you.
                    </p>
                  </div>
                </div>
                <div className="mt-5 md:mt-0 md:col-span-2">
                  <form>
                    <div className="shadow overflow-hidden sm:rounded-md">
                      <div className="px-4 py-5 bg-white sm:p-6">
                        <div className="grid grid-cols-6 gap-6">

                          <div className="col-span-6 sm:col-span-3 lg:col-span-3">
                            <label for="birth_time" className="block text-sm font-medium text-gray-700">Birth Time</label>
                            <input type="text" value={user.birth_time} onChange={(event) => handleOnChange(event)} name="birth_time" id="birth_time" className="mt-1 p-2 focus:ring-indigo-500 border-2 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                          </div>

                          <div className="col-span-6 sm:col-span-3 lg:col-span-3">
                            <label for="birth_place" className="block text-sm font-medium text-gray-700">Birth Place</label>
                            <input type="text" value={user.birth_place} onChange={(event) => handleOnChange(event)} name="birth_place" id="birth_place" className="mt-1 p-2 focus:ring-indigo-500 border-2 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                          </div>

                          <div className="col-span-6 md:col-span-3 lg:col-span-3">
                            <label for="maritial_status" className="block text-sm font-medium text-gray-700">Maritial Status</label>
                            <select id="maritial_status" name="maritial_status" value={user.maritial_status} onChange={(event) => handleOnChange(event)} className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                              <option>Select</option>
                              {
                                maritial_status.map((item, index) => {
                                  return <option key={index} value={item}>{item}</option>
                                })
                              }
                            </select>
                          </div>

                          <div className="col-span-6 md:col-span-3 lg:col-span-3">
                            <label for="cast" className="block text-sm font-medium text-gray-700">Cast</label>
                            <select id="cast" name="cast" value={user.cast} onChange={(event) => handleOnChange(event)} className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                              <option>Select</option>
                              {
                                cast.map((item, index) => {
                                  return <option key={index} value={item}>{item}</option>
                                }
                                )
                              }
                            </select>
                          </div>

                          <div className="col-span-6 md:col-span-3 lg:col-span-3">
                            <label for="rashi" className="block text-sm font-medium text-gray-700">Rashi</label>
                            <select id="rashi" name="rashi" value={user.rashi} onChange={(event) => handleOnChange(event)} className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                              <option>Select</option>
                              {
                                rashi.map((item, index) => {
                                  return <option key={index} value={item}>{item}</option>
                                }
                                )
                              }
                            </select>
                          </div>

                          <div className="col-span-6 md:col-span-3 lg:col-span-3 mt-8">
                            <div className="flex items-start">
                              <div className="flex items-center h-5">
                                <input id="manglik" checked={user.manglik} onChange={(event) => checkboxOnChange(event)} name="manglik" type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                              </div>
                              <div className="ml-3 text-sm">
                                <label for="manglik" className="font-medium text-gray-700">Manglik</label>
                              </div>
                            </div>
                          </div>

                          <div className="col-span-6 sm:col-span-3 lg:col-span-3">
                            <label for="gotra" className="block text-sm font-medium text-gray-700">Gotra</label>
                            <input type="text" value={user.gotra} onChange={(event) => handleOnChange(event)} name="gotra" id="birth_place" className="mt-1 p-2 focus:ring-indigo-500 border-2 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                          </div>

                        </div>
                      </div>
                      <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                        <button type="submit" onClick={(e) => editUser(e)} className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" style={{backgroundColor:"#F74300"}}>
                          {
                            isSubmit ?
                            <img src={loader} alt={loader} style={{width:'1.3rem', margin:"0 5px"}}/> :
                            'Save'  
                          }
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div> :
            null
        }

        {/* Partner's Criteria */}
        {
          isPartner ?
            <div className="mt-10 sm:mt-0">
              <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-1">
                  <div className="px-4 sm:px-0">
                    <h3 className="text-lg font-bold leading-6 text-gray-900">Partner's Criteria</h3>
                    <p className="mt-1 text-sm text-gray-600">
                      What are you finding in your partner
                    </p>
                  </div>
                </div>
                <div className="mt-5 md:mt-0 md:col-span-2">
                  <form>
                    <div className="shadow overflow-hidden sm:rounded-md">
                      <div className="px-4 py-5 bg-white sm:p-6">
                        <div className="grid grid-cols-6 gap-6">
                          
                          <div className="col-span-6 sm:col-span-3 lg:col-span-3">
                            <div className="flex items-center mt-2">
                              <label className="mr-3 block text-sm font-medium text-gray-700">
                                Searching For
                              </label>
                              <input id="male" value="Male" onChange={(event) => handleOnChange(event)} checked={user.expectation === "Male"} name="expectation" type="radio" className="-mt-2 focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300" />
                              <label for="male" className="ml-3 block text-sm font-medium text-gray-700">
                                Male
                              </label>
                              <input id="female" value="Female" onChange={(event) => handleOnChange(event)} checked={user.expectation === "Female"} name="expectation" type="radio" className="ml-3 -mt-2 focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300" />
                              <label for="female" className="ml-3 block text-sm font-medium text-gray-700">
                                Female
                              </label>
                            </div>
                          </div>

                          <div className="col-span-6 md:col-span-3 lg:col-span-3">
                            <label for="expectation_type" className="block text-sm font-medium text-gray-700">
                              Partner Type
                            </label>
                            <select id="expectation_type" name="expectation_type" value={user.expectation_type} onChange={(event) => handleOnChange(event)} className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                              <option>Select</option>
                              {
                                maritial_status.map((item, index) => {
                                  return <option key={index} value={item}>{item}</option>
                                })
                              }
                            </select>
                          </div>

                          <div className="col-span-6 md:col-span-3 lg:col-span-3">
                            <label for="expectation_cast" className="block text-sm font-medium text-gray-700">
                              Cast
                            </label>
                            <select id="expectation_cast" name="expectation_cast" value={user.expectation_cast} onChange={(event) => handleOnChange(event)} className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                              <option>Select</option>
                              {
                                cast.map((item, index) => {
                                  return <option key={index} value={item}>{item}</option>
                                })
                              }
                            </select>
                          </div>

                          <div className="col-span-6 sm:col-span-3 lg:col-span-3">
                            <div className="flex items-center mt-6">
                              <input id="expectation_manglik" checked={user.expectation_manglik} onChange={(event) => checkboxOnChange(event)} name="expectation_manglik" type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                              <label for="expectation_manglik" className="ml-3 block text-sm font-medium text-gray-700">
                                Manglik
                              </label>
                            </div>
                          </div>
                          
                          <div className="col-span-6 md:col-span-3 lg:col-span-3">
                            <label for="expectation_height" className="block text-sm font-medium text-gray-700">
                              Height
                            </label>
                            <select id="expectation_height" name="expectation_height" value={user.expectation_height} onChange={(event) => handleOnChange(event)} className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                              <option>Select</option>
                              {
                                height.map((item, index) => {
                                  return <option key={index} value={item}>{item} Feet</option>
                                })
                              }
                            </select>
                          </div>

                          <div className="col-span-6 md:col-span-3 lg:col-span-3">
                            <label for="expectation_weight" className="block text-sm font-medium text-gray-700">
                              Weight
                            </label>
                            <select id="expectation_weight" name="expectation_weight" value={user.expectation_weight} onChange={(event) => handleOnChange(event)} className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                              <option>Select</option>
                              {
                                weight.map((item, index) => {
                                  return <option key={index} value={item}>{item} Feet</option>
                                })
                              }
                            </select>
                          </div>

                          <div className="col-span-6 md:col-span-3 lg:col-span-3">
                            <label for="expectation_age_difference" className="block text-sm font-medium text-gray-700">
                              Age Difference
                            </label>
                            <select id="expectation_age_difference" name="expectation_age_difference" value={user.expectation_age_difference} onChange={(event) => handleOnChange(event)} className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                              <option>Select</option>
                              {
                                expectation_age_difference.map((item, index) => {
                                  return <option key={index} value={item}>{item}</option>
                                })
                              }
                            </select>
                          </div>

                          <div className="col-span-6 sm:col-span-3 lg:col-span-3">
                            <label for="expectation_city" className="block text-sm font-medium text-gray-700">City</label>
                            <input type="text" value={user.expectation_city} onChange={(event) => handleOnChange(event)} name="expectation_city" id="expectation_city" className="mt-1 p-2 focus:ring-indigo-500 border-2 border-2 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                          </div>

                          <div className="col-span-6 md:col-span-3 lg:col-span-3">
                            <label for="expectation_education_type" className="block text-sm font-medium text-gray-700">Education Type</label>
                            <select id="expectation_education_type" name="expectation_education_type" value={user.expectation_education_type} onChange={(event) => handleOnChange(event)} className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                              <option>Select</option>
                              {
                                education_type.map((item, index) => {
                                  return <option key={index} value={item}>{item}</option>
                                }
                                )
                              }
                            </select>
                          </div>
                          
                          <div className="col-span-6 sm:col-span-3 lg:col-span-3">
                            <label for="expectation_education_title" className="block text-sm font-medium text-gray-700">Education Title</label>
                            <input type="text" value={user.expectation_education_title} onChange={(event) => handleOnChange(event)} name="expectation_education_title" id="expectation_education" className="mt-1 p-2 focus:ring-indigo-500 border-2 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                          </div>

                          <div className="col-span-6 md:col-span-3 lg:col-span-3">
                            <label for="expectation_occupation_type" className="block text-sm font-medium text-gray-700">Occupation Type</label>
                            <select id="expectation_occupation_type" name="expectation_occupation_type" value={user.expectation_occupation_type} onChange={(event) => handleOnChange(event)} className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                              <option>Select</option>
                              {
                                occupation_type.map((item, index) => {
                                  return <option key={index} value={item}>{item}</option>
                                }
                                )
                              }
                            </select>
                          </div>

                          <div className="col-span-6 sm:col-span-3 lg:col-span-3">
                            <label for="expectation_occupation_title" className="block text-sm font-medium text-gray-700">Occupation Title</label>
                            <input type="text" value={user.expectation_occupation_title} onChange={(event) => handleOnChange(event)} name="expectation_occupation_title" id="expectation_occupation_title" className="mt-1 p-2 focus:ring-indigo-500 border-2 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                          </div>

                        </div>
                      </div>
                      <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                        <button type="submit" onClick={(e) => editUser(e)} className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" style={{backgroundColor:"#F74300"}}>
                          {
                            isSubmit ?
                            <img src={loader} alt={loader} style={{width:'1.3rem', margin:"0 5px"}}/> :
                            'Save'  
                          }
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div> :
            null
        }
      </div>
      <Footer />
    </>
  );
}