import React, { useState, useEffect } from 'react';
import { sessionUser } from '../../../services/auth';

export default function Bio() {

  const [user, setUser] = useState({});

  useEffect(() => {
    sessionUser().then(res => {
      setUser(res.data.user);
    });  
  }, [])

  return (
    <>
      <div className="bg-white shadow overflow-hidden sm:rounded-lg mt-4 mb-8">
        <div className="px-4 py-2 sm:px-6">
          <h3 className="text-lg leading-6 font-bold text-gray-900">
            Bio Information
        </h3>
        </div>
        <div className="border-t border-gray-200">
          <dl>
            <div className="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Height
              </dt>
              <dd className="capitalize mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {user.height ? user.height : 'N/A'}
              </dd>
            </div>
            <div className="bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Weight
              </dt>
              <dd className="capitalize mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {user.weight ? user.weight : 'N/A'}
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Complextion
              </dt>
              <dd className="capitalize mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {user.complextion ? user.complextion : 'N/A'}
              </dd>
            </div>
            <div className="bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Diet
              </dt>
              <dd className="capitalize mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {
                  user.diet === "Vegeterian" ? user.diet : 
                  user.diet === "Non-Vegeterian" ? user.diet : 
                  user.diet === "Both" ? "Vegeterian, Non-Vegeterian" : 'N/A'
                }
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Physical Disablities
              </dt>
              <dd className="capitalize mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {
                  user.physical_disablities === true ? "Yes" : 
                  user.physical_disablities === false? "No" :
                  'N/A'
                }
              </dd>
            </div>
            <div className="bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
               Spectacles
              </dt>
              <dd className="capitalize mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {
                  user.spectacles === true ? "Yes" : 
                  !user.spectacles  === false ? "No" :
                  'N/A'
                } 
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Blood Group
              </dt>
              <dd className="capitalize mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {user.blood_group ? user.blood_group : 'N/A'}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </>
  );
}

