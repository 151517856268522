import React, { useState, useEffect } from 'react';
import { sessionUser } from '../../../services/auth';

export default function Parents(props) {

  const [user, setUser] = useState({});

  useEffect(() => {
    sessionUser().then(res => {
      setUser(res.data.user);
    });  
  }, [])

  return (
    <>
      <div className="bg-white shadow overflow-hidden sm:rounded-lg mb-8">
        <div className="px-4 py-2 sm:px-6">
          <h3 className="text-lg leading-6 font-bold text-gray-900">
            Parents Information
        </h3>
        </div>
        <div className="border-t border-gray-200">
          <dl>
            <div className="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Father
              </dt>
              <dd className="capitalize mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {user.father === true ? "Yes" : user.father === false ? "No" : 'N/A'}
              </dd>
            </div>
            <div className="bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Father's Name
              </dt>
              <dd className="capitalize mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {user.middle_name ? user.middle_name : 'N/A'}
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Mother
              </dt>
              <dd className="capitalize mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {user.mother === true ? "Yes" : user.mother === false ? "No" : 'N/A'}
              </dd>
            </div>
            <div className="bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Mother's Name
              </dt>
              <dd className="capitalize mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {user.mother_name ? user.mother_name : 'N/A'}
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Brother
              </dt>
              <dd className="capitalize mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {user.brother ? user.brother : 'N/A'}
              </dd>
            </div>
            <div className="bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Sister
              </dt>
              <dd className="capitalize mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {user.sister ? user.sister : 'N/A'}
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Mama's Last Name
              </dt>
              <dd className="capitalize mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {user.mama_last_name ? user.mama_last_name : 'N/A'}
              </dd>
            </div>
            <div className="bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Parent Resident City
              </dt>
              <dd className="capitalize mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {user.parent_resident_city ? user.parent_resident_city : 'N/A'}
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Parent Occupation
              </dt>
              <dd className="capitalize mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {user.parent_occupation ? user.parent_occupation : 'N/A'}
              </dd>
            </div>
            <div className="bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Family Welth
              </dt>
              <dd className="capitalize mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {user.family_welth ? user.family_welth : 'N/A'}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </>
  );
}