import React from 'react';
import { baseApi } from '../../../services/constants';
import axios from 'axios';
import noRecords from '../../../assets/img/undraw-hiring-cyhs.svg';
import { MailIcon, PhoneIcon, UserIcon, ChatAltIcon, BackspaceIcon, ArrowSmLeftIcon, ArrowSmRightIcon } from '@heroicons/react/solid';

const paginationItem = 10;

export default class Messages extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      messageList: [],
      perPageItem: paginationItem,
      fromItem: 1,
      toItem: paginationItem,
      currentPageNumber: 1,
      totalPagenumber: 0,
      totalDataItem: 0,
      messages: false
    };
    this.getMessages = this.getMessages.bind(this);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }

  componentDidMount() {
    this.getMessages();
  }

  async getMessages(data) {
    let itemIndex = (this.state.currentPageNumber - 1) * this.state.perPageItem;
    await axios({
      method: 'get',
      url: `${baseApi}open/getmessages?per_page=${this.state.perPageItem}&from=${itemIndex}`,
      headers: { "Authorization": localStorage.getItem("access_token") }
    })
      .then(result => {
        if (result.data && result.data.messages.length > 0) {
          this.setState({ totalPagenumber: Math.ceil(result.data.count / 10) });
          this.setState({ totalDataItem: result.data.count });
          this.setState({ messageList: result.data.messages });
        }
        else {
          this.setState({ messageList: [] });
        }
      })
      .catch(error => {
        this.setState({ messageList: [] });
      });
  }

  async next() {
    window.scrollTo(0, 0)
    await this.setState({ currentPageNumber: this.state.currentPageNumber + 1 });
    this.setState({ fromItem: this.state.fromItem + this.state.perPageItem });
    this.setState({ toItem: this.state.toItem + this.state.perPageItem });
    this.getMessages();
  }

  async previous() {
    window.scrollTo(0, 0)
    await this.setState({ fromItem: this.state.fromItem - this.state.perPageItem });
    this.setState({ toItem: this.state.toItem - this.state.perPageItem });
    this.setState({ currentPageNumber: this.state.currentPageNumber - 1 });
    this.getMessages();
  }

  closePopup(data) {
    this.setState({ messages: data })
  }

  render() {

    let noRecordsView = <div style={{
      textAlign: 'center',
      padding: '4rem',
      background: '#F8F8F8',
      margin: '1rem 0'
    }}> <img src={noRecords} style={{ margin: 'auto' }} /> <h5> No data </h5>
    </div>

    let from = this.state.fromItem;
    let to = this.state.toItem;

    return (
      <>
        <div className="container">
          <div className="row">
            <div className="col-md-2 mb-8">
              <p className="text-left text-4xl xl:text-4xl my-8 font-bold"> Messages </p>
            </div>
            <div className="col-md-10 mb-8">
              <div className="overflow-x-auto lg:overflow-x-none">
                <div className="py-2 align-middle inline-block min-w-full">
                  {
                    this.state.messageList.length > 0 ?
                      this.state.messageList.map((item, index) => {
                        return <div key={index} className="p-4 overflow-hidden m-2 border border-gray-200 sm:rounded-lg">
                          <div className="flex items-center">
                            <div className="row">
                              <div className="col-md-6 col-lg-4 mt-2 sm:mt-4 lg:mt-0">
                                <div className="text-lg font-medium text-gray-900">
                                  <UserIcon className="h-5 w-5 mr-3 float-left" />
                                  {item.first_name} {item.last_name}
                                </div>
                              </div>
                              <div className="col-md-6 col-lg-5 mt-2 sm:mt-4 lg:mt-0">
                                <div className="text-lg font-medium text-gray-900">
                                  <MailIcon className="h-5 w-5 mr-3 float-left" /> {item.email}
                                </div>
                              </div>
                              <div className="col-md-6 col-lg-3 mt-2 sm:mt-4 lg:mt-0">
                                <div className="text-lg font-medium text-gray-900">
                                  <PhoneIcon className="h-5 w-5 mr-3 float-left" /> {item.phone}
                                </div>
                              </div>
                              <div className="col-md-6 col-lg-12 mt-2 sm:mt-4 lg:mt-0">
                                <div className="text-lg font-medium text-gray-900">
                                  <ChatAltIcon className="h-5 w-5 mr-3 float-left" /> {item.message}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      }) : noRecordsView
                  }
                </div>
              </div>
              {
                this.state.messageList.length > 0 ?
                  <div style={{ fontSize: '0.9rem', backgroundColor: "#F74300", float: 'right', marginTop: "2rem", padding: '1rem' }}>
                    <ul style={{ margin: 0, padding: 0, fontFamily: 'Overpass, sans-serif', color: '#fff' }}>
                      <li style={{ display: "inline", marginRight: "3px" }}>{from < 9 ? `0${from}` : from}</li>
                      <li style={{ display: "inline", marginRight: "3px" }}> - </li>
                      <li style={{ display: "inline", marginRight: "3px" }}>{to < this.state.totalDataItem ? to : this.state.totalDataItem}</li>
                      <li style={{ display: "inline", marginRight: "3px" }}> of </li>
                      <li style={{ display: "inline", marginRight: "3px" }}> {this.state.totalDataItem}</li>
                      <li style={{ display: "inline", marginRight: "3px" }}>
                        {
                          (this.state.currentPageNumber === 1 || this.state.totalDataItem.length <= 10) ?
                            <ArrowSmLeftIcon className="h-7 w-7 ml-3 inline" style={{ color: "#FB916A" }} /> :
                            <ArrowSmLeftIcon className="h-7 w-7 ml-3 inline" onClick={this.previous} />
                        }
                      </li>
                      <li style={{ display: "inline", marginRight: "3px" }}>
                        {
                          (this.state.totalDataItem.length === 0 || this.state.currentPageNumber === this.state.totalPagenumber) ?
                            <ArrowSmRightIcon className="h-7 w-7 ml-2 inline" style={{ color: "#FB916A" }} /> :
                            <ArrowSmRightIcon className="h-7 w-7 ml-2 inline" onClick={this.next} />
                        }
                      </li>
                    </ul>
                  </div> : ''
              }
            </div>
          </div>
        </div>
      </>
    );
  }
}