/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import rocket from '../../assets/img/rocLaunch1.png';
import loader from '../../assets/img/loader.gif';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers';
import * as Yup from 'yup';
import { baseApi } from '../../services/constants';
import Navbar from '../Home/SmallHome/components/Navbar';
import Footer from '../Home/SmallHome/components/Footer';
import { useHistory } from 'react-router-dom';

const axios = require('axios');

export default function Login() {

  const history = useHistory();
  const [isSubmit, setIsSubmit] = useState(false);

  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .required('Email or Mobile number is required'),
    password: Yup.string()
      .min(6, 'Password must be at least 6 characters')
      .required('Password is required'),
  });

  const { register, handleSubmit, reset, errors } = useForm({
    resolver: yupResolver(validationSchema)
  });

  const [successfull, setSuccessfull] = useState(false);
  const [onError, setOnError] = useState(false);
  const [onErrorMessage, setOnErrorMessage] = useState('');

  function onSubmit(data) {
    setIsSubmit(true);
    axios({
      method: "post",
      url: `${baseApi}user/login`,
      data: data
    }).then(result => {
      if (result.data.status === 'success') {
        let user = result.data.user
        localStorage.setItem("role", user.role);
        localStorage.setItem("access_token", user.access_token);
        setOnError(false)
        setSuccessfull(true);
        setIsSubmit(false);
        reset();
        history.push('/');
      }
      else if (result.data.status === 'failed') {
        setOnErrorMessage(result.data.message)
        setOnError(true);
        setIsSubmit(false);
      }
    })
  }

  return (
    <>
      <Navbar />
      <div className="flex relative rounded h-auto bg-white">
        <div className="hidden xl:block" style={{ width: 400 }}>
          <img src={rocket} loading="lazy" alt="" className="z-10 relative -left-0.5" />
        </div>
        <div className="w-9/12 w-11/12 mx-auto">
          <div className="w-full flex-col flex items-center">
            <div>
              <div className="md:grid sm:grid-cols-6 md:grid-cols-6 md:gap-6">
                <div className="md:col-span-1">
                  <div className="px-4 sm:px-0 mt-4"></div>
                </div>
                <div className="mt-3 md:mt-0 md:col-span-6">
                  <form onSubmit={handleSubmit(onSubmit)} onReset={reset} style={{width:'100% !important'}}>
                    <div className="shadow sm:rounded-md sm:overflow-hidden">
                      <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                        <div className="grid grid-cols-6 gap-6">
                          <div className="col-span-6 sm:col-span-6 sm:col-span-6">
                            <h3 className="text-2xl font-bold leading-6 text-gray-900 text-center">Login</h3>
                            <p className="mt-1 text-sm text-gray-600 text-center">Access your account</p>
                          </div>
                          <div className="col-span-6 sm:col-span-6 sm:col-span-6">
                            <input
                              ref={register}
                              type="text"
                              className="border focus:outline-none border-gray-300 py-2 lg:px-6 px-2 rounded w-full text-sm" 
                              placeholder="Username *"
                              name="username"
                            />
                            <p className="text-red-500 font-semibold text-md mt-2">{errors.username?.message}</p>
                          </div>
                          <div className="col-span-6 sm:col-span-6 sm:col-span-6">
                            <input
                              ref={register}
                              type="password"
                              className="border focus:outline-none border-gray-300 py-2 lg:px-6 px-2 rounded w-full text-sm"
                              placeholder="Password *"
                              name="password"
                            />
                            <p className="text-red-500 font-semibold text-md mt-2">{errors.password?.message}</p>
                          </div>
                          <div className="col-span-6 sm:col-span-6 sm:col-span-6">
                            {
                              successfull ?
                                <p className="text-center font-bold text-green-600 mt-0 mb-4 text-base w-11/12 mx-auto font-thin">
                                  Login Successfully
                                  </p> :
                                null
                            }
                            {
                              onError ?
                                <p className="text-center font-bold text-red-600 mt-0 mb-4 capitalize text-base w-11/12 mx-auto font-thin">
                                  {onErrorMessage}
                                </p> :
                                null
                            }
                            <button type="submit" style={{ backgroundColor: "#F74300" }}
                              className="py-2 px-4 w-full text-center rounded text-white text-sm cursor-pointer"
                            >
                              {
                                isSubmit ?
                                  <img src={loader} alt={loader} style={{ width: '1.2rem', left: '50%', position: 'relative' }} /> :
                                  'Login'
                              }
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}