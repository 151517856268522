import React, { useEffect, useState } from 'react';
import loadable from '@loadable/component';
import Navbar from '../Home/SmallHome/components/Navbar';
import About from './About';
import Bio from './Bio';
import EducationJob from './EducationJob';
import Location from './Location';
import Parents from './Parents';
import Others from './Others';
import Expectation from './Expectation';
import { PencilIcon } from '@heroicons/react/solid';
import { Link } from 'react-router-dom';

const Footer = loadable(() => import('../Home/SmallHome/components/Footer'));

export default function Profile() {

  useEffect(() => {
    document.title = "Profile";
    window.scrollTo(0, 0);
  }, [])

  return (
    <>
      <Navbar />
      <div className="mt-4 pb-10 w-10/12 mx-auto relative">
        <p className="text-left text-4xl xl:text-4xl mb-8 font-bold"> Profile </p>
        <Link to="/edit-profile" style={{ color: "#fff" }}>
          <span className="flex-1 flex-col absolute right-0 top-0">
            <button className="inline-flex items-center px-4 py-2 rounded-md shadow-sm text-sm font-medium text-white-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" style={{ backgroundColor: "#F74300" }}>
              <PencilIcon className="h-5 w-5 mr-3" /> Edit
            </button>
          </span>
        </Link>
        <About />
        <Bio />
        <Location />
        <EducationJob />
        <Parents />
        <Others />
        <Expectation />
      </div>
      <Footer />
    </>
  );
}