/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import rocket from '../../assets/img/rocLaunch1.png';
import loader from '../../assets/img/loader.gif';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers';
import * as Yup from 'yup';
import { baseApi, height, education_type, occupation_type } from '../../services/constants';
import Navbar from '../Home/SmallHome/components/Navbar';
import Footer from '../Home/SmallHome/components/Footer';

const axios = require('axios');

export default function SignUp() {

  const [isSubmit, setIsSubmit] = useState(false);
  const [successfull, setSuccessfull] = useState(false);
  const [onError, setOnError] = useState(false);

  const validationSchema = Yup.object().shape({
    first_name: Yup.string()
      .required('First Name is required'),
    last_name: Yup.string()
      .required('Last name is required'),
    phone: Yup.string()
      .min(10, 'Password must in 10 digits')
      .required('Mobile number is required'),
    height: Yup.string()
      .required('Height is required'),
    gender: Yup.string()
      .required('Gender  is required'),
    dob: Yup.string()
      .required('Date of Birth is required'),
    education_type: Yup.string()
      .required('Education Type is required'),
    education_title: Yup.string()
      .required('education Title is required'),
      occupation_type: Yup.string()
      .required('Occupation Type is required'),
    occupation_title: Yup.string()
      .required('Occupation Title is required'),
    home_town: Yup.string()
      .required('Hpme Town is required'),
    current_city: Yup.string()
      .required('Current City is required'),
    username: Yup.string()
      .required('Username is required'),
    password: Yup.string()
      .min(6, 'Password must in 6 letters')
      .required('Password is required'),
  });

  const { register, handleSubmit, reset, errors } = useForm({
    resolver: yupResolver(validationSchema)
  });

  function onSubmit(data) {
    setOnError(false);
    setIsSubmit(true);
    axios({
      method: "post",
      url: `${baseApi}user/signup`,
      data: data
    }).then(result => {
      if (result.data.status === 'success') {
        setOnError(false)
        setSuccessfull(true);
        setIsSubmit(false);
        reset();
      }
      else if (result.data.status === 'failed') {
        setOnError(true);
        setIsSubmit(false);
      }
    })
  }

  return (
    <>
      <Navbar />
      <div className="flex relative rounded h-auto bg-white">
        <div className="hidden xl:block" style={{ width: 400 }}>
          <img src={rocket} loading="lazy" alt="" className="z-10 relative -left-0.5" />
        </div>
        <div className="w-9/12 w-11/12 mx-auto">
          <div className="w-full flex-col flex items-center">
            <div>
              <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-1">
                  <div className="px-4 sm:px-0 mt-4"></div>
                </div>
                <div className="mt-3 md:mt-0 md:col-span-2">
                  <form onSubmit={handleSubmit(onSubmit)} onReset={reset} className="w-full">
                    <div className="shadow sm:rounded-md sm:overflow-hidden">
                      <div className="px-4 py-4 bg-white space-y-6 sm:p-6">
                        <div className="grid grid-cols-6 gap-6">
                          <div className="col-span-6 sm:col-span-6 sm:col-span-6">
                            <h3 className="text-2xl font-bold leading-6 text-gray-900 text-center">Signup</h3>
                            <p className="mt-1 text-sm text-gray-600 text-center">Please fill the form and get started</p>
                          </div>
                          <div className="col-span-6 sm:col-span-3 sm:col-span-3">
                            <input
                              ref={register}
                              type="text"
                              className="mr-2 border focus:outline-none border-gray-300 py-2 lg:px-6 px-2 rounded w-full text-sm"
                              placeholder="First Name *"
                              id="first_name"
                              name="first_name"
                            />
                            <p className="text-red-500 font-semibold text-md ">{errors.first_name?.message}</p>
                          </div>
                          <div className="col-span-6 sm:col-span-3 sm:col-span-3">
                            <input
                              ref={register}
                              type="text"
                              className="border focus:outline-none border-gray-300 py-2 lg:px-6 px-2 rounded w-full text-sm"
                              placeholder="Last Name *"
                              id="last_name"
                              name="last_name"
                            />
                            <p className="text-red-500 font-semibold text-md ">{errors.last_name?.message}</p>
                          </div>
                          <div className="col-span-6 sm:col-span-3 sm:col-span-3">
                            <input
                              ref={register}
                              type="text"
                              className="mr-2 border focus:outline-none border-gray-300 py-2 lg:px-6 px-2 rounded w-full text-sm"
                              placeholder="Username *"
                              name="username"
                            />
                            <p className="text-red-500 font-semibold text-md ">{errors.username?.message}</p>
                          </div>
                          <div className="col-span-6 sm:col-span-3 sm:col-span-3">
                            <input
                              ref={register}
                              type="password"
                              className="border focus:outline-none border-gray-300 py-2 lg:px-6 px-2 rounded w-full text-sm"
                              placeholder="Password *"
                              name="password"
                            />
                            <p className="text-red-500 font-semibold text-md ">{errors.password?.message}</p>
                          </div>
                          <div className="col-span-6 sm:col-span-3 sm:col-span-3">
                            <input
                              ref={register}
                              type="text"
                              className="border focus:outline-none border-gray-300 mr-2 py-2 lg:px-6 px-2 rounded w-full text-sm"
                              placeholder="Mobile *"
                              name="phone"
                            />
                            <p className="text-red-500 font-semibold text-md ">{errors.phone?.message}</p>
                          </div>
                          <div className="col-span-6 sm:col-span-3 lg:col-span-3 md:mt-0">
                            <input
                              type="text"
                              name="dob"
                              id="dob"
                              ref={register}
                              placeholder="Date of Birth"
                              onFocus={(e) => (e.currentTarget.type = "date")}
                              onBlur={(e) => (e.currentTarget.type = "text")}
                              className="border focus:outline-none border-gray-300 mr-2 py-2 lg:px-6 px-2 rounded w-full text-sm"
                            />
                            <p className="text-red-500 font-semibold text-md ">{errors.dob?.message}</p>
                          </div>
                          <div className="col-span-6 sm:col-span-3 sm:col-span-3">
                            <input
                              ref={register}
                              type="text"
                              className="border focus:outline-none border-gray-300 mr-2 py-2 lg:px-6 px-2 rounded w-full text-sm"
                              placeholder="Home Town *"
                              name="home_town"
                            />
                            <p className="text-red-500 font-semibold text-md ">{errors.home_town?.message}</p>
                          </div>
                          <div className="col-span-6 sm:col-span-3 sm:col-span-3">
                            <input
                              ref={register}
                              type="text"
                              className="border focus:outline-none border-gray-300 mr-2 py-2 lg:px-6 px-2 rounded w-full text-sm"
                              placeholder="Current City *"
                              name="current_city"
                            />
                            <p className="text-red-500 font-semibold text-md ">{errors.current_city?.message}</p>
                          </div>
                          <div className="col-span-6 sm:col-span-3 sm:col-span-3">
                            <select
                              ref={register}
                              id="education_type"
                              name="education_type"
                              className="border focus:outline-none border-gray-300 py-2 lg:px-6 px-2 rounded w-full text-sm"
                            >
                              <option value="">Education Type</option>
                              {
                                education_type.map((item, index) => {
                                  return <option key={index} value={item}>{item}</option>
                                })
                              }
                            </select>
                            <p className="text-red-500 font-semibold text-md ">{errors.education_type?.message}</p>
                          </div>
                          <div className="col-span-6 sm:col-span-3 sm:col-span-3">
                            <input
                              ref={register}
                              type="text"
                              className="border focus:outline-none border-gray-300 mr-2 py-2 lg:px-6 px-2 rounded w-full text-sm"
                              placeholder="Education Title *"
                              name="education_title"
                            />
                            <p className="text-red-500 font-semibold text-md ">{errors.education_title?.message}</p>
                          </div>
                          <div className="col-span-6 sm:col-span-3 sm:col-span-3">
                            <select
                              ref={register}
                              id="occupation_type"
                              name="occupation_type"
                              className="border focus:outline-none border-gray-300 py-2 lg:px-6 px-2 rounded w-full text-sm"
                            >
                              <option value="">Occupation Type</option>
                              {
                                occupation_type.map((item, index) => {
                                  return <option key={index} value={item}>{item}</option>
                                })
                              }
                            </select>
                            <p className="text-red-500 font-semibold text-md ">{errors.occupation_type?.message}</p>
                          </div>
                          <div className="col-span-6 sm:col-span-3 sm:col-span-3">
                            <input
                              ref={register}
                              type="text"
                              className="border focus:outline-none border-gray-300 mr-2 py-2 lg:px-6 px-2 rounded w-full text-sm"
                              placeholder="Occupation Title *"
                              name="occupation_title"
                            />
                            <p className="text-red-500 font-semibold text-md ">{errors.occupation_title?.message}</p>
                          </div>
                          
                          <div className="col-span-6 sm:col-span-3 sm:col-span-3">
                            <select
                              name="height"
                              ref={register}
                              className="border focus:outline-none border-gray-300 py-2 lg:px-6 px-2 rounded w-full text-sm"
                            >
                              <option value="">Height</option>
                              {
                                height.map((item, index) => {
                                  return <option value={item}>{item} Feet</option>
                                })
                              }
                            </select>
                            <p className="text-red-500 font-semibold text-md ">{errors.height?.message}</p>
                          </div>
                          <div className="col-span-6 sm:col-span-3 sm:col-span-3">
                            <div className="flex items-center mt-2">
                              <input id="male" ref={register} name="gender" value="Male" type="radio" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300" />
                              <label for="male" className="ml-3 block text-sm font-medium text-gray-700">
                                Male
                              </label>
                              <input id="female" ref={register} name="gender" value="Female" type="radio" className="ml-3 focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300" />
                              <label for="female" className="ml-3 block text-sm font-medium text-gray-700">
                                Female
                              </label>
                            </div>
                            <p className="text-red-500 font-semibold text-md ">{errors.gender?.message}</p>
                          </div> 
                          <div className="col-span-6 sm:col-span-6 sm:col-span-6">
                            {
                              successfull ?
                                <p className="text-center font-bold text-green-600 mb-4 text-base lg:w-2/5 w-11/12 mx-auto font-thin">
                                  Registerd Successfully
                                </p> :
                                null
                            }
                            {
                              onError ?
                                <p className="text-center font-bold text-red-600 mb-4 text-base lg:w-2/5 w-11/12 mx-auto font-thin">
                                  Choose another username
                                </p> :
                                null
                            }
                            <button type="submit" style={{ backgroundColor: "#F74300" }}
                              className="py-2 px-4 w-full text-center rounded text-white text-sm cursor-pointer"
                            >
                              {
                                isSubmit ?
                                  <img src={loader} alt={loader} style={{ width: '1.2rem', left: '50%', position: 'relative' }} /> :
                                  'Sign Up'
                              }
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}