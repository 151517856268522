import React, { useState, useEffect } from 'react';
import { MailIcon, PhoneIcon, UserIcon, CalendarIcon, AtSymbolIcon, BadgeCheckIcon } from '@heroicons/react/solid';
import { sessionUser, getMemberDetails, deleteMember } from '../../../services/auth';
import { useHistory } from "react-router-dom";

export default function About(props) {

  const history = useHistory();

  const [user, setUser] = useState({});
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [accountRole, setAccountRole] = useState(false);

  const deleteUser = async () => {
    await deleteMember(props.user_id)
      .then(async (res) => {
        console.log(res)
        if (res.data.status === "success") {
          history.push("/members")
        }
      })
  }

  useEffect(async () => {
    if (localStorage.getItem("access_token")) {
      sessionUser().then(res => {
        new Date(res.data.user.subscription.razorpay_payment_date) > new Date() ?
          setIsSubscribed(true) :
          setIsSubscribed(false)
        setAccountRole(res.data.user.role);
      });
    }
    await getMemberDetails(props.user_id)
      .then(async (res) => {
        await setUser(res.data.user)
      })
  }, []);

  console.log("user details : ".user)

  return (
    <>
      <div className="lg:flex lg:items-center lg:mb-8 xl:mb-8 lg:justify-between">
        {
          user.avatar ?
            <div className="flex -space-x-1 overflow-hidden relative">
              <a href={user.avatar} target="_blank">
                <img
                  className="inline-block mr-8 h-36 w-36 overflow-hidden rounded-full ring-2 ring-white"
                  src={user.avatar}
                  alt={user.first_name + user.last_name}
                />
              </a>
              <div className="absolute bottom-0 mx-auto bg-black text-gray-200 rounded-md p-2 bg-opacity-60">
                <a href={user.avatar} target="_blank" className="hover:text-white">
                  View Profile Image
                </a>
              </div>
            </div> :
            <span className="inline-block mr-8 h-36 w-36 rounded-full overflow-hidden bg-gray-100 mr-4">
              <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
              </svg>
            </span>
        }
        <div className="flex-1 min-w-0 ml-0 lg:ml-8 mt-8 lg:mt-0">
          <h2 className="capitalize text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            {
              (accountRole === "ADMIN" || isSubscribed) ?
                user.first_name + ' ' : ''
            }
            {
              (accountRole === "ADMIN" || isSubscribed) ?
                user.middle_name + ' ' : ''
            }
            {user.last_name ? user.last_name : ''}
            {/* {
              new Date(user.subscription.razorpay_payment_date) > new Date() ?
              <BadgeCheckIcon className="h-7 w-7 mr-3 inline ml-4 text-green-500" /> :
              null 
            }  */}
          </h2>
          <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
            {
              user.username ?
                <div className="mt-2 flex items-center text-sm text-gray-500">
                  <AtSymbolIcon className="h-5 w-5 mr-3" /> {user.username}
                </div>
                : null
            }
            {
              (accountRole === "ADMIN" || isSubscribed) ?
                <div>
                  {
                    user.email ?
                      <div className="mt-2 flex items-center text-sm text-gray-500">
                        <MailIcon className="h-5 w-5 mr-3" /> {user.email}
                      </div>
                      : null
                  }
                </div> :
                null
            }
            {
              (accountRole === "ADMIN" || isSubscribed) ?
                <div>
                  {
                    user.phone ?
                      <div className="mt-2 flex items-center text-sm text-gray-500">
                        <PhoneIcon className="h-5 w-5 mr-3" /> {user.phone}
                      </div>
                      : null
                  }
                </div> :
                null
            }
            {
              user.gender ?
                <div className="mt-2 flex items-center text-sm text-gray-500">
                  <UserIcon className="h-5 w-5 mr-3" /> {user.gender}
                </div>
                : null
            }
            {
              user.dob ?
                <div className="mt-2 flex items-center text-sm text-gray-500">
                  <CalendarIcon className="h-5 w-5 mr-3" /> {`${new Date(user.dob).getDate()}/${new Date(user.dob).getMonth() + 1}/${new Date(user.dob).getFullYear()}`}
                </div>
                : null
            }
          </div>
        </div>
        {
          accountRole === "ADMIN" &&
          <button style={{
            backgroundColor: "#f74300",
            borderolor: "1px solid #f74300",
            color: "#fff",
            fontWeight: "700",
            padding: '0.5rem 1rem',
            borderRadius: "0.5rem",
            margin:"1rem 0"
          }}
            onClick={() => deleteUser()}
          > Delete </button>
        }
      </div>
    </>
  );
}

