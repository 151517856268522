import React, { useEffect, useState } from 'react';
import loadable from '@loadable/component';
import Navbar from '../Home/SmallHome/components/Navbar';
import About from './About';
import Bio from './Bio';
import EducationJob from './EducationJob';
import Location from './Location';
import Parents from './Parents';
import Others from './Others';
import Expectation from './Expectation';
import { sessionUser } from '../../services/auth';

const Footer = loadable(() => import('../Home/SmallHome/components/Footer'));

export default function UserDetails(props) {

  const [accountRole, setAccountRole] = useState('');
  const [isSubscribed, setIsSubscribed] = useState(false);

  useEffect(() => {
    document.title = "User";
    if(localStorage.getItem("access_token")){
      sessionUser().then(res => {
        new Date(res.data.user.subscription.razorpay_payment_date) > new Date() ? 
        setIsSubscribed(true) : 
        setIsSubscribed(false)
        setAccountRole(res.data.user.role);
      });
    }
    window.scrollTo(0, 0)
  }, [])


  return (
    <>
      <Navbar />
      <div className="mt-4 pb-10 w-10/12 mx-auto relative">
        <p className="text-left text-4xl xl:text-4xl mb-8 font-bold"> User Details </p>
        <About user_id={props.match.params.id}/>
        <Bio user_id={props.match.params.id} />
        {
          (accountRole === "ADMIN" || isSubscribed) ?
          <Location user_id={props.match.params.id} />: 
          null
        }
        <EducationJob user_id={props.match.params.id} />
        <Parents user_id={props.match.params.id} />
        <Others user_id={props.match.params.id} />
        <Expectation user_id={props.match.params.id} />
      </div>
      <Footer />
    </>
  );
}