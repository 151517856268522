/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import rocket from '../../../../../../assets/img/rocLaunch1.png';
import loader from '../../../../../../assets/img/loader.gif';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers';
import * as Yup from 'yup';
import { baseApi } from '../../../../../../services/constants';
import ResetPassword from '../ResetPassword';
import { XCircleIcon} from '@heroicons/react/solid';

const axios = require('axios');

export default function ForgetPassword(props) {

  const [username, setUsername] = useState('');
  const [isResponse, setIsResponse] = useState(false);
  const [isResponseStatus, setIsResponseStatus] = useState('');
  const [isResponseMessage, setIsResponseMessage] = useState('');
  const [isSubmit, setIsSubmit] = useState(false);

  const closePopup = () => {
    props.setVisible(false)
  }

  const closeResetPassword = () => {
    setIsResponse(false);
    setIsResponseStatus('');
    setIsResponseMessage('');
  }

  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .required('Email or Mobile is required')
  });

  const { register, handleSubmit, reset, errors } = useForm({
    resolver: yupResolver(validationSchema)
  });

  async function onSubmit(data) {
    setIsSubmit(true);
    await setUsername(data)
    await axios({
      method: "get",
      url: `${baseApi}user/forgetpassword/${data.username}`
    })
      .then(result => {
        if (result.data) {
          setIsResponse(true);
          setIsResponseStatus(result.data.status);
          setIsResponseMessage(result.data.message);
          setIsSubmit(false);
        }
      })
  }

  return (
    <>
      <XCircleIcon className="h-7 w-7 ml-3 inline absolute cursor-pointer"
                   onClick={() => closePopup()} style={{zIndex: '9999', right: '2rem', top: '0.5rem'}}/>
      <div className="flex relative -top-4 rounded h-auto bg-white overflow-y-scroll">
        {
          (isResponseStatus === "" || isResponseStatus === "failed") ?
            <>
              <div className="hidden xl:block" style={{ width: 400 }}>
                <img
                  src={rocket}
                  loading="lazy"
                  alt="rocket"
                  className="z-10 relative -left-0.5"
                />
              </div>
              <div className="w-9/12 w-11/12 mx-auto">
                <p className="font-bold sm:text-3xl text-lg text-center mt-20 md:mt-14 lg:mt-14 font-medium">Forget Password</p>
                <p className="text-center mt-4 lg:mt-4 text-base w-11/12 mx-auto font-thin">
                  Get OTP to set new password
                </p>
                <div>
                  <div className="w-full flex-col flex items-center">
                    <form onSubmit={handleSubmit(onSubmit)} onReset={reset} className="w-full lg:w-1/2">
                      <div className="flex items-center mt-4">
                        <input
                          ref={register}
                          type="text"
                          className="border focus:outline-none border-gray-300 py-2 lg:px-6 px-2 rounded w-full text-sm" placeholder="First Name *"
                          placeholder="Username *"
                          name="username"
                        />
                      </div>
                      <div className="text-red-500 font-semibold text-md">{errors.username?.message}</div>
                       {
                        isResponse ?
                          <div className={`${isResponseStatus === 'success' ? 'text-green-500' : 'text-red-500'} font-semibold text-md mt-2`}>
                            {isResponseMessage}
                          </div> :
                          null
                      }
                      <div className="flex items-center mt-4">
                        <button type="submit"
                          className="py-2 px-4 w-full text-center rounded text-white text-sm cursor-pointer" style={{backgroundColor:"#F74300"}}
                        > 
                          {
                            isSubmit ?
                            <img src={loader} alt={loader} style={{width:'1.2rem', left:'50%', position:'relative'}}/> :
                            'Send OTP'  
                          }
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </>
            : null
        }
      </div>
      {
        setIsResponse && isResponseStatus === "success" ?
        <ResetPassword  closeResetPassword={closeResetPassword} username={username}/> : 
        null
      }
    </>
  );
}