import React from 'react';
import { ArrowSmLeftIcon, ArrowSmRightIcon, PlusCircleIcon, PencilAltIcon, TrashIcon } from '@heroicons/react/solid';
import { Link } from 'react-router-dom';
import { baseApi } from '../../services/constants';
import axios from 'axios';
import noRecords from '../../assets/img/undraw-hiring-cyhs.svg';
import loadable from '@loadable/component';
import Navbar from '../Home/SmallHome/components/Navbar';
import AddSuccessStory from './AddSuccessStory';
import { sessionUser } from '../../services/auth';

const Footer = loadable(() => import('../Home/SmallHome/components/Footer'));
const paginationItem = 10;

export default class SuccessStory extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      user: [],
      perPageItem: paginationItem,
      fromItem: 1,
      toItem: paginationItem,
      currentPageNumber: 1,
      totalPagenumber: 0,
      totalDataItem: 0,
      isAddSuccessStory: false,
      sessionUserRole: "",
    };
    this.getSuccessStories = this.getSuccessStories.bind(this);
    this.deleteSuccessStories = this.deleteSuccessStories.bind(this);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.getSessionUser();
  }

  async getSessionUser(){
    await sessionUser().then(res => {
      let user = res.data.user;
      this.setState({sessionUserRole:user.role})
    });
  }

  componentDidMount() {
    this.getSuccessStories();
  }

  async deleteSuccessStories(id) {
    await axios({
      method: 'get',
      url: `${baseApi}open/deletesuccessstory/${id}`,
    })
      .then(result => {
        if (result.data && result.data.status === "success") {
          this.getSuccessStories();
        }
      })
      .catch(error => {
        console.log(error)
      });
  }

  async getSuccessStories() {
    let itemIndex = (this.state.currentPageNumber - 1) * this.state.perPageItem;
    await axios({
      method: 'get',
      url: `${baseApi}open/getsuccessstory?per_page=${this.state.perPageItem}&from=${itemIndex}`,
    })
      .then(result => {
        if (result.data && result.data.success_stories.length > 0) {
          this.setState({ totalPagenumber: Math.ceil(result.data.count / 10) });
          this.setState({ totalDataItem: result.data.count });
          this.setState({ user: result.data.success_stories });
        }
        else {
          this.setState({ user: [] });
        }
      })
      .catch(error => {
        this.setState({ user: [] });
      });
  }

  async next() {
    window.scrollTo(0, 0)
    await this.setState({ currentPageNumber: this.state.currentPageNumber + 1 });
    this.setState({ fromItem: this.state.fromItem + this.state.perPageItem });
    this.setState({ toItem: this.state.toItem + this.state.perPageItem });
    this.getSuccessStories();
  }

  async previous() {
    window.scrollTo(0, 0)
    await this.setState({ fromItem: this.state.fromItem - this.state.perPageItem });
    this.setState({ toItem: this.state.toItem - this.state.perPageItem });
    this.setState({ currentPageNumber: this.state.currentPageNumber - 1 });
    this.getSuccessStories();
  }

  closePopup(data) {
    this.setState({ isAddSuccessStory: data });
    this.getSuccessStories();
  }

  render() {

    let noRecordsView = <div style={{
      textAlign: 'center',
      padding: '4rem',
      background: '#F8F8F8',
      margin: '1rem 0'
    }}> <img src={noRecords} style={{ margin: 'auto' }} /> <h5> No data </h5>
    </div>

    let from = this.state.fromItem;
    let to = this.state.toItem;

    return (
      <>
        <Navbar />
        <div className="container">
          <div className="row">
            <div className="col-md-3 mb-8">
              <p className="text-left text-4xl xl:text-4xl my-8 font-bold"> Success Stories </p>
              {
                localStorage.getItem("role") === "ADMIN" ?
                <button onClick={() => this.setState({ isAddSuccessStory: true })} className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  <PlusCircleIcon className="h-5 w-5 mr-3" /> Add Success Story
                </button> : ''
              }
            </div>
            {
              !this.state.isAddSuccessStory ?
                <div className="col-md-9 mb-8">
                  <div className="overflow-x-auto lg:overflow-x-none">
                    <div className="py-2 align-middle inline-block min-w-full">
                      {
                        this.state.user.length > 0 ?
                          this.state.user.map((item, index) => {
                            return (
                              <div key={index} className="p-4 overflow-hidden m-2 border border-gray-200 sm:rounded-lg">
                                <div className="flex items-center">
                                  <div className="flex-shrink-0 h-10 w-10">
                                    {
                                      item.users[0].avatar ?
                                        <img className="h-10 w-10 rounded-full" src={item.users[0].avatar} alt={item.users[0].first_name + item.users[0].last_name} /> :
                                        <span className="inline-block h-12 w-12 rounded-full overflow-hidden bg-gray-100">
                                          <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                          </svg>
                                        </span>
                                    }
                                  </div>
                                  <div className="ml-4 w-36">
                                    <div className="text-lg font-bold text-gray-900">
                                      {item.users[0].first_name} {item.users[0].last_name}
                                    </div>
                                  </div>
                                  <div className="mx-4 w-24">
                                    <Link to={`/user/${item.users[0]._id}`} target="_blank">
                                      <button className="py-2 px-9 flex rounded text-white text-sm cursor-pointer" style={{backgroundColor:"#F74300"}}>
                                        Details
                                  </button>
                                    </Link>
                                  </div>

                                  <div className="flex-shrink-0 h-10 w-10 ml-12">
                                    {
                                      item.users[1].avatar ?
                                        <img className="h-10 w-10 rounded-full" src={item.users[1].avatar} alt={item.users[1].first_name + item.users[1].last_name} /> :
                                        <span className="inline-block h-12 w-12 rounded-full overflow-hidden bg-gray-100">
                                          <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                          </svg>
                                        </span>
                                    }
                                  </div>
                                  <div className="ml-4 w-36">
                                    <div className="text-lg font-bold text-gray-900">
                                      {item.users[1].first_name} {item.users[1].last_name}
                                    </div>
                                  </div>
                                  <div className="mx-4 w-24">
                                    <Link to={`/user/${item.users[1]._id}`} target="_blank">
                                      <button className="py-2 px-9 flex rounded text-white text-sm cursor-pointer" style={{backgroundColor:"#F74300"}}>
                                        Details
                                      </button>
                                    </Link>
                                  </div>
                                  {
                                    this.state.sessionUserRole === "ADMIN" ?
                                    <TrashIcon onClick={() => this.deleteSuccessStories(item._id)} className="h-7 w-20 ml-4 text-red-600 inline cursor-pointer hover:text-red-400"/>
                                    :null
                                  }
                                    </div>
                              </div>
                            )
                          }) : noRecordsView
                      }
                    </div>
                  </div>
                  {
                    this.state.user.length > 0 ?
                      <div style={{ fontSize: '0.9rem', backgroundColor: "#F74300", float: 'right', marginTop: "2rem", padding: '1rem' }}>
                        <ul style={{ margin: 0, padding: 0, fontFamily: 'Overpass, sans-serif', color: '#fff' }}>
                          <li style={{ display: "inline", marginRight: "3px" }}>{from < 9 ? `0${from}` : from}</li>
                          <li style={{ display: "inline", marginRight: "3px" }}> - </li>
                          <li style={{ display: "inline", marginRight: "3px" }}>{to < this.state.totalDataItem ? to : this.state.totalDataItem}</li>
                          <li style={{ display: "inline", marginRight: "3px" }}> of </li>
                          <li style={{ display: "inline", marginRight: "3px" }}> {this.state.totalDataItem}</li>
                          <li style={{ display: "inline", marginRight: "3px" }}>
                            {
                              (this.state.currentPageNumber === 1 || this.state.totalDataItem.length <= 10) ?
                                <ArrowSmLeftIcon className="h-7 w-7 ml-3 inline" style={{ color: "#FB916A" }} /> :
                                <ArrowSmLeftIcon className="h-7 w-7 ml-3 inline" onClick={this.previous} />
                            }
                          </li>
                          <li style={{ display: "inline", marginRight: "3px" }}>
                            {
                              (this.state.totalDataItem.lenght === 0 || this.state.currentPageNumber === this.state.totalPagenumber) ?
                                <ArrowSmRightIcon className="h-7 w-7 ml-2 inline" style={{ color: "#FB916A" }} /> :
                                <ArrowSmRightIcon className="h-7 w-7 ml-2 inline" onClick={this.next} />
                            }
                          </li>
                        </ul>
                      </div> : ''
                  }
                </div> :
                <AddSuccessStory visible={this.state.isAddSuccessStory} setVisible={(data) => this.closePopup(data)} />
            }
          </div>
        </div>
        <Footer />
      </>
    );
  }
}