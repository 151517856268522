import React, { useState } from 'react';
import phone from '../../../assets/img/phone-white.svg';
import chat from '../../../assets/img/chat-white.svg';
import whats from '../../../assets/img/whatsWhite.svg';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers';
import * as Yup from 'yup';
import { baseApi } from '../../../services/constants';
const axios = require('axios');

function Engagement() {

  const validationSchema = Yup.object().shape({
    first_name: Yup.string()
      .required('First Name is required'),
    last_name: Yup.string()
      .required('Last name is required'),
    email: Yup.string()
      .required('Email is required')
      .email('Email is invalid'),
    phone: Yup.string()
      .required('Mobile number is required'),
    message: Yup.string()
      .min(10, 'Message must be at least 10 characters')
      .required('Message is required'),
  });

  const { register, handleSubmit, errors, reset } = useForm({
    resolver: yupResolver(validationSchema)
  });

  const [successfull, setSuccessfull] = useState(false);
  const [onError, setOnError] = useState(false);

  function onSubmit(data) {
    axios({
      method:"post",
      url:`${baseApi}open/contact`,
      data:data
    }).then(result => {
      if(result.data.status === 'success'){
        setOnError(false)
        setSuccessfull(true);
        reset();
        setTimeout(() => {
          setSuccessfull(false);
        }, 3000);
      }
      else if(result.data.status === 'failed'){
        setOnError(true);
      }
    })
  }

  return (
    <div className="flex lg:flex-row flex-col lg:my-20 my-10 justify-between  xl:px-28">

      {/* Desktop */}
      <div className="mx-auto flex flex-col lg:border-none px-4 lg:px-0 lg:mt-0  justify-center items-center">
        <p className="text-2xl text-center font-semibold">Send Us a Message</p>
        <p className="text-sm grey-text text-center mt-2"> A member of our team will get back to you shortly.</p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex items-center mt-8">
            <input
              ref={register}
              type="text"
              className="border focus:outline-none border-gray-300 py-2 lg:px-6 px-2 rounded w-2/4 mr-2 text-sm"
              placeholder="First Name *"
              id="first_name"
              name="first_name"
            />
            <input
              ref={register}
              type="text"
              className="border focus:outline-none border-gray-300 py-2 lg:px-6 px-2 rounded w-2/4 text-sm"
              placeholder="Last Name *"
              id="last_name"
              name="last_name"
            />
          </div>
          <div className="flex items-center mt-2 font-semibold text-md text-red-500 justify-between">
            <p> {errors.first_name?.message} </p>
            <p className="float-right">{errors.last_name?.message}</p>
          </div>
          <div className="flex items-center mt-4">
            <input
              ref={register}
              type="text"
              className="border focus:outline-none border-gray-300 py-2  lg:px-6 px-2 rounded w-2/4 mr-2 text-sm"
              placeholder="Email *"
              name="email"
            />
            <input
              ref={register}
              type="text"
              className="border focus:outline-none border-gray-300 py-2 lg:px-6 px-2 rounded w-2/4 text-sm"
              placeholder="Phone Number *"
              name="phone"
            />
          </div>
          <div className="flex items-center mt-2 font-semibold text-md text-red-500 justify-between">
            <p>{errors.email?.message}</p>
            <p className="float-right">{errors.phone?.message}</p>
          </div>
          <textarea
            ref={register}
            className="border focus:outline-none border-gray-300 w-full py-2 lg:px-6 px-2 rounded mt-4 text-sm"
            placeholder="Message *"
            rows="5"
            name="message"
          />
           <div className="flex items-center mt-2 font-semibold text-md text-red-500 justify-between">
             {errors.message?.message}
          </div>
          { 
            successfull ?
            <p className="text-center font-bold text-green-900 mt-4 text-base lg:w-2/5 w-11/12 mx-auto font-thin">
              Message send successfull
            </p> :
            null
          }
           { 
            onError ?
            <p className="text-center font-bold text-red-900 mt-4 text-base lg:w-2/5 w-11/12 mx-auto font-thin">
              Something went wrong
            </p> :
            null
          }

          <div className="p-2 pl-2 mt-28 pt-0 rounded-md border-2 ml-16 mr-16" style={{ width: "11.4rem", margin: 'auto', marginTop: ' 25px' }}>
            <button type="submit" style={{backgroundColor:"#F74300"}} 
              className="text-white w-full flex items-center mt-2 mr-2 justify-between border-none focus:outline-none py-2 px-7 rounded px-5">
              Let’s Connect
            </button>
          </div>
        </form>
      </div>
      <div className=" mx-auto lg:w-96 mt-8 lg:mt-0">
        <p className="lg:text-2xl text-xl text-center font-semibold">Kickstart Engagement</p>
        <p className="text-sm grey-text mt-2 text-center">
          It takes less than a minute
        </p>
        <div className="p-2 pl-2 mt-32 pt-0 rounded-md border-2 ml-16 mr-16" style={{ width: "11.4rem", margin: 'auto', marginTop: ' 25px' }}>
          <button type="submit" className="text-white lg:w-40 w-full flex items-center mt-2 mr-2 justify-between border-none focus:outline-none py-2 px-7 rounded px-5" style={{backgroundColor:"#F74300"}}>
            <img src={phone} loading="lazy" alt="phone-white" className="contactBtn" />
            <p className="relativeContact">
              <a href="tel:+919226590907"> Phone Call </a></p>
          </button>
        </div>
        <div className="p-2 pl-2 mt-32 pt-0 rounded-md border-2 ml-16 mr-16" style={{ width: "11.4rem", margin: 'auto', marginTop: ' 25px' }}>
          <button type="submit" className="text-white lg:w-40 w-full flex items-center mt-2 mr-2 justify-between border-none focus:outline-none py-2 px-7 rounded px-5" style={{backgroundColor:"#F74300"}}>
            <img src={chat} loading="lazy" alt="phone-white" className="contactBtn" />
            <p className="relativeContact">
              <a href="sms:+919226590907">Chat</a>
            </p>
          </button>
        </div>

        <div className="p-2 pl-2 mt-32 pt-0 rounded-md border-2 ml-16 mr-16" style={{ width: "11.4rem", margin: 'auto', marginTop: ' 25px' }}>
          <button type="submit" className="text-white lg:w-40 w-full flex items-center mt-2 mr-2 justify-between border-none focus:outline-none py-2 px-7 rounded px-5" style={{backgroundColor:"#F74300"}}>
            <img src={whats} loading="lazy" alt="phone-white" className="contactBtn" />
            <p className="relativeContact">
              <a href="https://api.whatsapp.com/send?phone=+919226590907" target="_blank">
                Whatsapp
            </a>
            </p>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Engagement;
