// export const baseApi = "http://localhost:8000/api/";
// export const baseApi = "https://smpankajsalunke.herokuapp.com/api/"; // salunkemaratha1@gmail.com
export const baseApi = "https://smbackend.onrender.com/api/"; // deployed on onrender.com using sagarninave@gmail.com

export const weight = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100]

export const height = ['1', '1.1', '1.2', '1.3', '1.4', '1.5', '1.6', '1.7', '1.8', '1.9', '1.10', '1.11', '2', '2.1', '2.2', '2.3', '2.4', '2.5', '2.6', '2.7', '2.8', '2.9', '2.10', '2.11', '3', '3.1', '3.2', '3.3', '3.4', '3.5', '3.6', '3.7', '3.8', '3.9', '3.10', '3.11', '4', '4.1', '4.2', '4.3', '4.4', '4.5', '4.6', '4.7', '4.8', '4.9', '4.10', '4.11', '5', '5.1', '5.2', '5.3', '5.4', '5.5', '5.6', '5.7', '5.8', '5.9', '5.10', '5.11', '6', '6.1', '6.2', '6.3', '6.4', '6.5', '6.6', '6.7', '6.8', '6.9', '6.10', '6.11', '7', '7.1', '7.2', '7.3', '7.4', '7.5', '7.6', '7.7', '7.8', '7.9', '7.10', '7.11', '8', '8.1', '8.2', '8.3', '8.4', '8.5', '8.6', '8.7', '8.8', '8.9', '8.10', '8.11', '9', '9.1', '9.2', '9.3', '9.4', '9.5', '9.6', '9.7', '9.8', '9.9', '9.10', '9.11', '10'];

export const education_type = ["10th/SSC", "12th/HSC", "Graduation", "Post-Graduation", "Ph.D"];

export const occupation_type = ["Public", "Private", "Government", "Business"];

export const complexition = ["Fair", "Dark", "Normal"];

export const diet = ["Vegeterian", "Non-Vegeterian", "Both"];

export const maritial_status = ["Married", "Unmarried", "Divorcee", "Widower", "Widow"];

export const cast = ["Maratha"];

export const rashi = ["Mesh", "Vrushabh", "Mithun", "Kark", "Sinha", "Kanya", "Tula", "Vrischik", "Dhanu", "Makar", "Kumbh", "Meen"];

export const expectation_age_difference = [1,2,3,4,5,6,7,8,9,10];

export const razorpay ={
  key : 'rzp_test_Vnh1huDFa5D6mn',
  secret : 'ki2iC8zjiGx9Y5RlpKNvMCix'
}