import React, { useEffect } from 'react';
import loadable from '@loadable/component';
import Navbar from '../Home/SmallHome/components/Navbar';
import SearchFilterResult from './SearchFilterResult';
const Footer = loadable(() => import('../Home/SmallHome/components/Footer'));

export default function Members() {

  useEffect(() => {
    document.title = "Members"
  }, [])

  return (
    <>
      <Navbar />
      <div className="container">
        <SearchFilterResult/>
      </div>
      <Footer />
    </>
  );
}