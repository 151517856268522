/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from 'react';
import SmallHome from './SmallHome';

function Home() {
  useEffect(() => {
    document.title = 'Home';
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <SmallHome />
    </div>
  );
}

export default Home;
