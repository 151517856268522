/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import loadable from '@loadable/component';
import Navbar from './components/Navbar';
const Hero = loadable(() => import('./components/Hero'));
const Service = loadable(() => import('./components/Service'));
const AboutUs = loadable(() => import('./components/AboutUs'));
const Statistics = loadable(() => import('./components/Statistics'));
const MeetUsers = loadable(() => import('./components/MeetUsers'));
const Footer = loadable(() => import('./components/Footer'));

function SmallHome() {
  return (
    <div>
      <Navbar />
      {/* <MeetUsers /> */}
      <Hero />
      <Service />
      <AboutUs />
      <Statistics />
      <Footer />
    </div>
  );
}

export default SmallHome;