import React, { useEffect } from 'react';
import Navbar from '../Home/SmallHome/components/Navbar';
import Footer from '../Home/SmallHome/components/Footer';
import Engagement from './Engagement';
import Messages from './Messages';

function Contact() {
  useEffect(() => {
    document.title = 'Contact';
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Navbar />
      { localStorage.getItem("role") !== "ADMIN" ? <Engagement /> : null }
      { localStorage.getItem("role") === "ADMIN" ? <Messages/> : null }
      <Footer />
    </div>
  );
}

export default Contact;
