import React, { useState } from 'react';
import {
  height, weight, education_type, complexition, diet, maritial_status, cast, rashi, occupation_type
} from '../../../services/constants';

export default function SearchFilter(props) {

  const [userFilter, setUserFilter] = useState({
    gender: '',
    physical_disablities: null,
    manglik: null,
    maritial_status: '',
    cast: '',
    rashi: '',
    height: '',
    weight: '',
    complextion: '',
    diet: '',
    education_type: '',
    occupation_type: '',
    home_town: '',
    current_city: '',
    first_name: '',
    last_name: ''
  });

  function handleOnChange(event) {
    setUserFilter({ ...userFilter, [event.target.name]: event.target.value })
  }

  function checkboxOnChange(event) {
    setUserFilter({ ...userFilter, [event.target.name]: event.target.checked })
  }

  function clearFilters() {
    setUserFilter({
      gender: '',
      physical_disablities: null,
      manglik: null,
      maritial_status: '',
      cast: '',
      rashi: '',
      height: '',
      weight: '',
      complextion: '',
      diet: '',
      education_type: '',
      occupation_type: '',
      home_town: '',
      current_city: ''
    })
    props.getUser()
  }

  function search() {
    Object.keys(userFilter).map(key => {
      (userFilter[key] === '' || userFilter[key] === null || userFilter[key] === undefined) && delete userFilter[key];
    })
    props.getUser(userFilter)
  }

  return (
    <>
      <p className="text-left text-lg mb-8 font-medium"> Filters
        <button onClick={() => props.closeFilter()}
          style={{
            backgroundColor: "#f74300",
            borderolor: "1px solid #f74300",
            color: "#fff",
            fontWeight: "700",
            padding: '0.2rem 1.5rem',
            borderRadius: "0.5rem",
            float: "right",
            fontSize: "1.2rem"
          }}
        >
          X
        </button>
      </p>

      {/* <div className="col-span-6 sm:col-span-3 lg:col-span-2 mt-2">
            <label for="first_name" className="block text-sm font-medium text-gray-700">First Name</label>
            <input type="text" value={userFilter.first_name} onChange={(event) => handleOnChange(event)} name="first_name" id="first_name" className="mt-1 p-2 focus:ring-indigo-500 border-2 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
          </div> */}

      <div className="col-span-6 sm:col-span-3 lg:col-span-2 mt-2">
        <label for="last_name" className="block text-sm font-medium text-gray-700">Last Name</label>
        <input type="text" value={userFilter.last_name} onChange={(event) => handleOnChange(event)} name="last_name" id="last_name" className="mt-1 p-2 focus:ring-indigo-500 border-2 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
      </div>

      <div className="col-span-6 sm:col-span-3 sm:col-span-3">
        <div className="flex items-center mt-2">
          <label for="gender" className="mr-3 block text-sm font-medium text-gray-700">
            Gender
          </label>
          <input id="male" name="gender" value="Male" onChange={(event) => handleOnChange(event)} checked={userFilter.gender === "Male"} type="radio" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300" />
          <label for="male" className="ml-3 block text-sm font-medium text-gray-700">
            Male
          </label>
          <input id="female" name="gender" value="Female" onChange={(event) => handleOnChange(event)} checked={userFilter.gender === "Female"} type="radio" className="ml-3 focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300" />
          <label for="female" className="ml-3 block text-sm font-medium text-gray-700">
            Female
          </label>
        </div>
      </div>

      <div className="col-span-6 md:col-span-3 lg:col-span-3 mt-2">
        <div className="flex items-start">
          <div className="flex items-center h-5">
            <input id="physical_disablities" checked={userFilter.physical_disablities} onChange={(event) => checkboxOnChange(event)} name="physical_disablities" type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
          </div>
          <div className="ml-3 text-sm">
            <label for="physical_disablities" className="font-medium text-gray-700">Physical Disablities</label>
          </div>
        </div>
      </div>

      <div className="col-span-6 md:col-span-3 lg:col-span-3 mt-2">
        <div className="flex items-start">
          <div className="flex items-center h-5">
            <input id="manglik" checked={userFilter.manglik} onChange={(event) => checkboxOnChange(event)} name="manglik" type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
          </div>
          <div className="ml-3 text-sm">
            <label for="manglik" className="font-medium text-gray-700">Manglik</label>
          </div>
        </div>
      </div>

      <div className="col-span-6 sm:col-span-3 lg:col-span-2 mt-2">
        <label for="home_town" className="block text-sm font-medium text-gray-700">Home Town</label>
        <input type="text" value={userFilter.home_town} onChange={(event) => handleOnChange(event)} name="home_town" id="home_town" className="mt-1 p-2 focus:ring-indigo-500 border-2 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
      </div>

      <div className="col-span-6 sm:col-span-3 lg:col-span-2 mt-2">
        <label for="current_city" className="block text-sm font-medium text-gray-700">Current City</label>
        <input type="text" value={userFilter.current_city} onChange={(event) => handleOnChange(event)} name="current_city" id="current_city" className="mt-1 p-2 focus:ring-indigo-500 border-2 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
      </div>

      <div className="col-span-6 md:col-span-3 lg:col-span-3 mt-2">
        <label for="maritial_status" className="block text-sm font-medium text-gray-700">Maritial Status</label>
        <select id="maritial_status" name="maritial_status" value={userFilter.maritial_status} onChange={(event) => handleOnChange(event)} className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
          <option>Select</option>
          {
            maritial_status.map((item, index) => {
              return <option key={index} value={item}>{item}</option>
            })
          }
        </select>
      </div>

      <div className="col-span-6 md:col-span-3 lg:col-span-3 mt-2">
        <label for="cast" className="block text-sm font-medium text-gray-700">Cast</label>
        <select id="cast" name="cast" value={userFilter.cast} onChange={(event) => handleOnChange(event)} className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
          <option>Select</option>
          {
            cast.map((item, index) => {
              return <option key={index} value={item}>{item}</option>
            }
            )
          }
        </select>
      </div>

      <div className="col-span-6 md:col-span-3 lg:col-span-3 mt-2">
        <label for="rashi" className="block text-sm font-medium text-gray-700">Rashi</label>
        <select id="rashi" name="rashi" value={userFilter.rashi} onChange={(event) => handleOnChange(event)} className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
          <option>Select</option>
          {
            rashi.map((item, index) => {
              return <option key={index} value={item}>{item}</option>
            })
          }
        </select>
      </div>

      <div className="col-span-6 md:col-span-3 lg:col-span-3 mt-2">
        <label for="height" className="block text-sm font-medium text-gray-700">Height</label>
        <select id="height" name="height" value={userFilter.height} onChange={(event) => handleOnChange(event)} className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
          <option>Select</option>
          {
            height.map((item, index) => {
              return <option key={index} value={item}>{item} Feet</option>
            })
          }
        </select>
      </div>

      <div className="col-span-6 md:col-span-3 lg:col-span-3 mt-2">
        <label for="education_type" className="block text-sm font-medium text-gray-700">Weight</label>
        <select id="weight" name="weight" value={userFilter.weight} onChange={(event) => handleOnChange(event)} className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
          <option>Select</option>
          {
            weight.map((item, index) => {
              return <option key={index} value={item}>{item}</option>
            })
          }
        </select>
      </div>

      <div className="col-span-6 md:col-span-3 lg:col-span-3 mt-2">
        <label for="complextion" className="block text-sm font-medium text-gray-700">Complextion</label>
        <select id="complextion" name="complextion" value={userFilter.complextion} onChange={(event) => handleOnChange(event)} className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
          <option>Select</option>
          {
            complexition.map((item, index) => {
              return <option key={index} value={item}>{item}</option>
            })
          }
        </select>
      </div>

      <div className="col-span-6 md:col-span-3 lg:col-span-3 mt-2">
        <label for="diet" className="block text-sm font-medium text-gray-700">Diet</label>
        <select id="diet" name="diet" value={userFilter.diet} onChange={(event) => handleOnChange(event)} className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
          <option>Select</option>
          {
            diet.map((item, index) => {
              return <option key={index} value={item}>{item}</option>
            })
          }
        </select>
      </div>

      <div className="col-span-6 md:col-span-3 lg:col-span-3 mt-2">
        <label for="education_type" className="block text-sm font-medium text-gray-700">Education Type</label>
        <select id="education_type" name="education_type" value={userFilter.education_type} onChange={(event) => handleOnChange(event)} className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
          <option>Select</option>
          {
            education_type.map((item, index) => {
              return <option key={index} value={item}>{item}</option>
            })
          }
        </select>
      </div>

      <div className="col-span-6 md:col-span-3 lg:col-span-3 mt-2">
        <label for="occupation_type" className="block text-sm font-medium text-gray-700">Occupation Type</label>
        <select id="occupation_type" name="occupation_type" value={userFilter.occupation_type} onChange={(event) => handleOnChange(event)} className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
          <option>Select</option>
          {
            occupation_type.map((item, index) => {
              return <option key={index} value={item}>{item}</option>
            })
          }
        </select>
      </div>

      <button onClick={() => search()}
        className='w-full mt-2 py-2 px-4 text-sm font-medium rounded-md text-white edit-profile-button focus:outline-none'>
        Search
      </button>
      <button onClick={() => clearFilters()}
        className='w-full mt-2 mb-4 py-2 px-4 text-sm font-medium rounded-md text-white edit-profile-button focus:outline-none'>
        Clear
      </button>
    </>
  );
}