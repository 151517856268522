/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import rocket from '../../../../../../assets/img/rocLaunch1.png';
import loader from '../../../../../../assets/img/loader.gif';
import Modal from 'react-awesome-modal';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers';
import * as Yup from 'yup';
import { baseApi } from '../../../../../../services/constants';
import { XCircleIcon} from '@heroicons/react/solid';

const axios = require('axios');

export default function SignUp(props) {

  const [isSubmit, setIsSubmit] = useState(false);
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
  });
  const [successfull, setSuccessfull] = useState(false);
  const [onError, setOnError] = useState(false);

  useEffect(() => {
    function handleResize() {
      setDimensions({
        width: window.innerWidth,
      });
    }
    window.addEventListener('resize', handleResize);
    return (_) => {
      window.removeEventListener('resize', handleResize);
    };
  });

  const closePopup = () => {
    props.setVisible(false);
    setOnError(false);
    setSuccessfull(false);
  }

  const validationSchema = Yup.object().shape({
    first_name: Yup.string()
      .required('First Name is required'),
    last_name: Yup.string()
      .required('Last name is required'),
    phone: Yup.string()
      .required('Mobile number is required'),
    username: Yup.string()
      .required('Username is required'),
    password: Yup.string()
      .min(6, 'Password must in 6 letters')
      .required('Password is required'),
  });

  const { register, handleSubmit, reset, errors } = useForm({
    resolver: yupResolver(validationSchema)
  });

  function onSubmit(data) {
    setOnError(false);
    setIsSubmit(true);
    axios({
      method: "post",
      url: `${baseApi}user/signup`,
      data: data
    }).then(result => {
      if (result.data.status === 'success') {
        setOnError(false)
        setSuccessfull(true);
        reset();
        setSuccessfull(false);
        closePopup();
        setIsSubmit(true);
      }
      else if (result.data.status === 'failed') {
        setOnError(true);
        setIsSubmit(false);
      }
    })
  }

  return (
    <Modal
      visible={props.visible}
      width={`${dimensions.width < 768 ? '100%' : '70%'}`}
      height={`${dimensions.width < 768 ? '100%' : '75%'}`}
      effect="fadeInLeft"
      onClickAway={() => {
        setOnError(false);
        setSuccessfull(false);
        props.setVisible(false);
      }}
      style={{overflow:"scroll"}}
    >
      <XCircleIcon className="h-7 w-7 ml-3 inline absolute cursor-pointer" onClick={() => closePopup()}
                   style={{zIndex: '9999', right: '2rem', top: '0.5rem'}}/>
      <div className="flex relative -top-4 rounded h-auto bg-white overflow-y-scroll">
        <div className="hidden xl:block" style={{ width: 400 }}>
          <img
            src={rocket}
            loading="lazy"
            alt=""
            className="z-10 relative -left-0.5"
          />
        </div>
        <div className="w-9/12 w-11/12 mx-auto">
          <p className="font-bold sm:text-3xl text-lg text-center mt-16 md:mt-14 lg:mt-14 font-medium">Signup</p>
          <p className="text-center mt-0 sm:mt-0 md:mt-4 lg:mt-4 text-base lg:w-2/5 w-11/12 mx-auto font-thin">
            Please fill the form and get started
          </p>
          {
            successfull ?
              <p className="text-center font-bold text-green-600 mt-4 text-base lg:w-2/5 w-11/12 mx-auto font-thin">
                Registerd Successfully
            </p> :
              null
          }
          {
            onError ?
              <p className="text-center font-bold text-red-600 mt-4 text-base lg:w-2/5 w-11/12 mx-auto font-thin">
                Choose another username
            </p> :
              null
          }
          <div>
            <div className="w-full flex-col flex items-center">
              <form onSubmit={handleSubmit(onSubmit)} onReset={reset} className="w-full lg:w-2/3">
                <div className="flex items-center mt-8">
                  <input
                    ref={register}
                    type="text"
                    className="mr-2 border focus:outline-none border-gray-300 py-2 lg:px-6 px-2 rounded w-full text-sm" placeholder="First Name *"
                    placeholder="First Name *"
                    id="first_name"
                    name="first_name"

                  />
                  <input
                    ref={register}
                    type="text"
                    className="ml-2 border focus:outline-none border-gray-300 py-2 lg:px-6 px-2 rounded w-full text-sm" placeholder="First Name *"
                    placeholder="Last Name *"
                    id="last_name"
                    name="last_name"
                  />
                </div>
                <div className="flex items-center">
                  <div className="text-red-500 font-semibold text-md w-1/2">{errors.first_name?.message}</div>
                  <div className="text-red-500 font-semibold text-md w-1/2 ml-2">{errors.last_name?.message}</div>
                </div>
                <div className="flex items-center mt-4">
                  <input
                    ref={register}
                    type="text"
                    className="mr-2 border focus:outline-none border-gray-300 py-2 lg:px-6 px-2 rounded w-full text-sm" placeholder="First Name *"
                    placeholder="Username *"
                    name="username"
                  />
                  <input
                    ref={register}
                    type="password"
                    className="ml-2 border focus:outline-none border-gray-300 py-2 lg:px-6 px-2 rounded w-full text-sm" placeholder="First Name *"
                    placeholder="Password *"
                    name="password"
                  />
                </div>
                <div className="flex items-center">
                  <div className="text-red-500 font-semibold text-md w-1/2">{errors.username?.message}</div>
                  <div className="text-red-500 font-semibold text-md w-1/2 ml-2">{errors.password?.message}</div>
                </div>
                <div className="flex items-center mt-4">
                  <input
                    ref={register}
                    type="text"
                    className="border focus:outline-none border-gray-300 py-2 lg:px-6 px-2 rounded w-full text-sm" placeholder="First Name *"
                    placeholder="Mobile *"
                    name="phone"
                  />
                </div>
                <div className="text-red-500 font-semibold text-md">{errors.phone?.message}</div>
                <div className="flex items-center mt-4">
                  <button type="submit"
                    className="py-2 px-4 w-full text-center rounded text-white text-sm cursor-pointer" style={{ backgroundColor: "#F74300" }}
                  > 
                    {
                      isSubmit ?
                      <img src={loader} alt={loader} style={{width:'1.2rem', left:'50%', position:'relative'}}/> :
                      'Sign Up'  
                    } 
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}