import React, { useState, useEffect } from 'react';
import { sessionUser, getMemberDetails } from '../../../services/auth';

export default function Others(props) {

  const [user, setUser] = useState({});

  useEffect(() => {
    getMemberDetails(props.user_id)
    .then(res => {
      setUser(res.data.user)
    })
  }, [])

  return (
    <>
      <div className="bg-white shadow overflow-hidden sm:rounded-lg mb-8">
        <div className="px-4 py-2 sm:px-6">
          <h3 className="text-lg leading-6 font-bold text-gray-900">
            Miscellaneous Information
          </h3>
        </div>
        <div className="border-t border-gray-200">
          <dl>
            <div className="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Birth Time
              </dt>
              <dd className="capitalize mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {user.birth_time ? user.birth_time : 'N/A'}
              </dd>
            </div>
            <div className="bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Birth Place
              </dt>
              <dd className="capitalize mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {user.birth_place ? user.birth_place : 'N/A'}
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Maritial Status 
              </dt>
              <dd className="capitalize mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {user.maritial_status ? user.maritial_status : 'N/A'}
              </dd>
            </div>
            <div className="bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
               Cast
              </dt>
              <dd className="capitalize mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {user.cast ? user.cast : 'N/A'}
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Rashi
              </dt>
              <dd className="capitalize mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {user.rashi ? user.rashi : 'N/A'}
              </dd>
            </div>
            <div className="bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
               Manglik
              </dt>
              <dd className="capitalize mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {user.manglik === true ? "Yes" : user.manglik === false ? "No" : 'N/A'}
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Gotra
              </dt>
              <dd className="capitalize mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {user.gotra ? user.gotra : 'N/A'}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </>
  );
}

