import React, { useState, useEffect } from 'react';
import { MailIcon, PhoneIcon, UserIcon, XCircleIcon, CalendarIcon, AtSymbolIcon, BadgeCheckIcon, CurrencyRupeeIcon, CheckCircleIcon } from '@heroicons/react/solid';
import axios from 'axios';
import { baseApi, razorpay } from '../../../services/constants';
import loader from '../../../assets/img/loader.gif';
import logo from '../../../assets/img/logo.png';
import { sessionUser } from '../../../services/auth';
import Modal from 'react-awesome-modal';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers';
import * as Yup from 'yup';

export default function About() {

  const [user, setUser] = useState({});
  const [userFullName, setUserFullName] = useState('');
  const [isSubscription, setIsSubscription] = useState('');
  const [userEmail, setUserEmail] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [isModel, setIsModel] = useState(false);
  const [dimensions, setDimensions] = useState({ width: window.innerWidth });
  const [isResponse, setIsResponse] = useState(false);
  const [isResponseStatus, setIsResponseStatus] = useState('');
  const [isResponseMessage, setIsResponseMessage] = useState('');
  const [isSubmit, setIsSubmit] = useState(false);

  useEffect(async () => {
    await sessionUser().then(res => {
      let user = res.data.user;
      setUserEmail(user.email);
      setUserPhone(user.phone.toString());
      setUser(user);
      setUserFullName(user.first_name + ' ' + user.last_name)
      setIsSubscription(user.subscription.razorpay_payment_date);
    });

    function handleResize() {
      setDimensions({
        width: window.innerWidth,
      });
    }
    window.addEventListener('resize', handleResize);
    return (_) => {
      window.removeEventListener('resize', handleResize);
    };
  }, [])

  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .required('Username is required')
  });

  const { register, handleSubmit, reset, errors } = useForm({
    resolver: yupResolver(validationSchema)
  });

  async function onSubmit(data) {
    setIsSubmit(true);
    await axios({
      method: "get",
      url: `${baseApi}open/manualpayment/${data.username}`,
      headers: { Authorization: localStorage.getItem("access_token") }
    })
      .then(result => {
        if (result.data.status === 'success') {
          setIsResponse(true);
          setIsResponseStatus(result.data.status);
          setIsResponseMessage(result.data.message);
          setIsSubmit(false);
          reset();
          setTimeout(() => {
            setIsModel(false);
          }, 2000)
         
        }
      })
  }

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement('script')
      script.src = src
      script.onload = () => {
        resolve(true)
      }
      script.onerror = () => {
        resolve(false)
      }
      document.body.appendChild(script)
    })
  }

  async function subscription(data) {
    await axios({
      method: 'post',
      data: data,
      url: `${baseApi}open/subscription`,
      headers: { "Authorization": localStorage.getItem("access_token") }
    })
      .then(result => {
        if (result && result.data && result.data.status === "success") {
          alert(result.data.message)
        }
      })
  }

  async function displayRazorpay() {
    const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')

    if (!res) {
      alert('Razorpay SDK failed to load. Are you online?')
      return
    }

    const result = await axios({
      method: 'get',
      url: `${baseApi}open/razorpay`,
      headers: { "Authorization": localStorage.getItem("access_token") }
    })

    let created_payment_order = result.data;

    const options = {
      key: razorpay.key,
      currency: created_payment_order.currency,
      amount: created_payment_order.amount.toString(),
      order_id: created_payment_order.order_id,
      name: 'Salunke Maratha',
      description: 'Try Premium Service to Full Access',
      image: logo,
      handler: async function (response) {
        await subscription(response);
      },
      prefill: {
        userFullName,
        email: userEmail,
        phone_number: userPhone
      }
    }
    const paymentObject = new window.Razorpay(options)
    paymentObject.open()
  }

  return (
    <>
      <div className="lg:flex lg:items-center lg:mb-8 xl:mb-8 lg:justify-between">
        {
          user.avatar ?
            <div className="flex -space-x-1 overflow-hidden relative">
              <a href={user.avatar} target="_blank">
                <img
                  className="inline-block mr-8 h-36 w-36 overflow-hidden rounded-full ring-2 ring-white"
                  src={user.avatar}
                  alt={user.first_name + user.last_name}
                />
              </a>
              <div className="absolute bottom-0 mx-auto bg-black text-gray-200 rounded-md p-2 bg-opacity-60">
                <a href={user.avatar} target="_blank" className="hover:text-white">
                  View Profile Image
              </a>
              </div>
            </div> :
            <span className="inline-block mr-8 h-36 w-36 rounded-full overflow-hidden bg-gray-100 mr-4">
              <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
              </svg>
            </span>
        }
        <div className="flex-1 min-w-0 ml-0 lg:ml-8 mt-8 lg:mt-0">
          <h2 className="capitalize text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            {user.first_name ? user.first_name : ''} {user.middle_name ? user.middle_name : ''} {user.last_name ? user.last_name : ''}
            {
              new Date(isSubscription) > new Date() ?
                <BadgeCheckIcon className="h-7 w-7 mr-3 inline ml-4 text-green-500" /> :
                null
            }
          </h2>
          <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
            {
              user.username ?
                <div className="mt-2 flex items-center text-sm text-gray-500">
                  <AtSymbolIcon className="h-5 w-5 mr-3" /> {user.username}
                </div>
                : null
            }
            {
              user.email ?
                <div className="mt-2 flex items-center text-sm text-gray-500">
                  <MailIcon className="h-5 w-5 mr-3" /> {user.email}
                </div>
                : null
            }
            {
              user.phone ?
                <div className="mt-2 flex items-center text-sm text-gray-500">
                  <PhoneIcon className="h-5 w-5 mr-3" /> {user.phone}
                </div>
                : null
            }
            {
              user.gender ?
                <div className="mt-2 flex items-center text-sm text-gray-500">
                  <UserIcon className="h-5 w-5 mr-3" /> {user.gender}
                </div>
                : null
            }
            {
              user.dob ?
                <div className="mt-2 flex items-center text-sm text-gray-500">
                  <CalendarIcon className="h-5 w-5 mr-3" /> {`${new Date(user.dob).getDate()}/${new Date(user.dob).getMonth() + 1}/${new Date(user.dob).getFullYear()}`}
                </div>
                : null
            }
          </div>
        </div>
        {
          user.role === "ADMIN" ?
          <button onClick={() => { setIsModel(true) }} style={{ backgroundColor: "#F74300", color:"#fff" }}
            className="inline-flex items-center px-4 py-2 mt-4 rounded-md shadow-sm text-sm font-medium">
            <CurrencyRupeeIcon className="h-5 w-5 mr-3" /> Add User Payment
          </button> :
          null
        }
      </div>
      <Modal
        visible={isModel}
        width={`${dimensions.width < 768 ? '100%' : '30%'}`}
        height={`${dimensions.width < 768 ? '100%' : '55%'}`}
        effect="fadeInLeft"
        onClickAway={() => { setIsModel(false) }}
      >
        <XCircleIcon className="h-7 w-7 ml-3 inline absolute cursor-pointer"
                   onClick={() => setIsModel(false)} style={{zIndex: '9999', right: '2rem', top: '0.5rem'}}/>
        <div className="flex relative -top-4 rounded h-auto bg-white overflow-y-scroll">
          <div className="w-9/12 w-11/12 mx-auto">
            <p className="font-bold sm:text-3xl text-lg text-center mt-20 md:mt-14 lg:mt-14 font-medium">
              Add User Payment  
            </p>
            <div>
              <div className="w-full flex-col flex items-center">
                <form onSubmit={handleSubmit(onSubmit)} onReset={reset} className="w-full lg:w-full px-4">
                  <div className="flex items-center mt-4">
                    <input
                      ref={register}
                      type="text"
                      className="border focus:outline-none border-gray-300 py-2 lg:px-6 px-2 rounded w-full text-sm" placeholder="First Name *"
                      placeholder="Username *"
                      name="username"
                    />
                  </div>
                  <div className="text-red-500 font-semibold text-md">{errors.username?.message}</div>
                  {
                    isResponse ?
                      <div className={`${isResponseStatus === 'success' ? 'text-green-500' : 'text-red-500'} font-semibold text-md mt-2`}>
                        {isResponseMessage}
                      </div> :
                      null
                  }
                  <div className="flex items-center mt-4">
                    <button type="submit"
                      className="py-2 px-4 w-full text-center rounded text-white text-sm cursor-pointer" style={{ backgroundColor: "#F74300" }}
                    >
                      {
                        isSubmit ?
                          <img src={loader} alt={loader} style={{ width: '1.2rem', left: '50%', position: 'relative' }} /> :
                          'Submit'
                      }
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

