/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import rocket from '../../../../../../assets/img/rocLaunch1.png';
import loader from '../../../../../../assets/img/loader.gif';
import Modal from 'react-awesome-modal';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers';
import * as Yup from 'yup';
import ForgetPassword from '../ForgetPassword';
import { baseApi } from '../../../../../../services/constants'; 
import { XCircleIcon} from '@heroicons/react/solid';

const axios = require('axios');

export default function Login(props) {

  const [isSubmit, setIsSubmit] = useState(false);
  const [isForget, setIsForget] = useState(false);
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
  });

  useEffect(() => {
    function handleResize() {
      setDimensions({
        width: window.innerWidth,
      });
    }
    window.addEventListener('resize', handleResize);
    return (_) => {
      window.removeEventListener('resize', handleResize);
    };
  });

  const closePopup = () => {
    reset();
    setOnError(false)
    setOnErrorMessage('')
    setSuccessfull(false);
    props.setVisible(false)
  }

  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .required('Email or Mobile number is required'),
    password: Yup.string()
      .min(6, 'Password must be at least 6 characters')
      .required('Password is required'),
  });

  const { register, handleSubmit, reset, errors } = useForm({
    resolver: yupResolver(validationSchema)
  });

  const [successfull, setSuccessfull] = useState(false);
  const [onError, setOnError] = useState(false);
  const [onErrorMessage, setOnErrorMessage] = useState('');

  function onSubmit(data) {
    setIsSubmit(true);
    axios({
      method:"post",
      url:`${baseApi}user/login`,
      data:data
    }).then(result => {
      if(result.data.status === 'success'){
        let user = result.data.user
        localStorage.setItem("role", user.role);
        localStorage.setItem("access_token", user.access_token);
        setOnError(false)
        setSuccessfull(true);
        reset();
        closePopup();
        setSuccessfull(false);
        setIsSubmit(false);
      }
      else if(result.data.status === 'failed'){
        setOnErrorMessage(result.data.message)
        setOnError(true);
        setIsSubmit(false);
      }
    })
  }

  const closeForgetPassword = () => {
    setIsForget(false);
  }

  return (
    <Modal
      visible={props.visible}
      width={`${dimensions.width < 768 ? '100%' : '70%'}`}
      height={`${dimensions.width < 768 ? '100%' : '75%'}`}
      effect="fadeInLeft"
      onClickAway={() => {
          setOnError(false)
          setOnErrorMessage('')
          setSuccessfull(false);
          props.setVisible(false)
        }
      }
    >
      <XCircleIcon className="h-7 w-7 ml-3 inline absolute cursor-pointer"
                   onClick={() => closePopup()} style={{zIndex: '9999', right: '2rem', top: '0.5rem'}}/>
      {
        !isForget ?
          <div className="flex relative -top-4 rounded h-auto bg-white overflow-y-scroll">
            <div className="hidden xl:block" style={{ width: 400 }}>
              <img
                src={rocket}
                loading="lazy"
                alt=""
                className="z-10 relative -left-0.5"
              />
            </div>
            <div className="w-9/12 w-11/12 mx-auto">
              <p className="font-bold sm:text-3xl text-lg text-center mt-20 md:mt-14 lg:mt-14 font-medium">Login</p>
              <p className="text-center mt-4 text-base lg:w-2/5 w-11/12 mx-auto font-thin">Access your account</p>
              <div>
                <div className="w-full flex-col flex items-center">
                  <form onSubmit={handleSubmit(onSubmit)} onReset={reset} className="w-full lg:w-1/2">
                    <div className="flex items-center mt-4">
                      <input
                        ref={register}
                        type="text"
                        className="border focus:outline-none border-gray-300 py-2 lg:px-6 px-2 rounded w-full text-sm" placeholder="First Name *"
                        placeholder="Username *"
                        name="username"
                      />
                    </div>
                    <div className="text-red-500 font-semibold text-md">{errors.username?.message}</div>
                    <div className="flex items-center mt-4">
                      <input
                        ref={register}
                        type="password"
                        className="border focus:outline-none border-gray-300 py-2 lg:px-6 px-2 rounded w-full text-sm" placeholder="First Name *"
                        placeholder="Password *"
                        name="password"
                      />
                    </div>
                    <div className="text-red-500 font-semibold text-md">{errors.password?.message}</div>
                    { 
                      successfull ?
                      <p className="text-center font-bold text-green-900 mt-4 text-base lg:w-2/5 w-11/12 mx-auto font-thin">
                        Login Successfully
                      </p> :
                      null
                    }
                    { 
                      onError ?
                      <p className="text-center font-bold text-red-900 mt-4 text-base lg:w-2/5 w-11/12 mx-auto font-thin">
                        {onErrorMessage}
                      </p> :
                      null
                    }
                    
                    <div className="flex items-center mt-4">
                      <button type="submit"
                        className="py-2 px-4 w-full text-center rounded text-white text-sm cursor-pointer mb-2" style={{backgroundColor:"#F74300"}}
                      > 
                        {
                          isSubmit ?
                          <img src={loader} alt={loader} style={{width:'1.2rem', left:'50%', position:'relative'}}/> :
                          'Login'  
                        }
                      </button>
                    </div>
                    <hr className="w-full m-auto mt-8" style={{border:"1px solid #F74300"}}/>
                    <div className="flex items-center mt-2">
                      <button type="reset" onClick={() => setIsForget(true)}
                        className="py-2 px-4 w-full text-center rounded text-white text-sm cursor-pointer" style={{backgroundColor:"#F74300"}}
                        > Forget Password </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div> :
          null
      }

      {
        isForget ?
        <ForgetPassword visible={isForget} setVisible={closeForgetPassword}/>:
        null
      }
    </Modal>
  );
}