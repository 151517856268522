import React, { useState, useEffect } from 'react';
import { sessionUser } from '../../../services/auth';

export default function EducationJob(props) {

  const [user, setUser] = useState({});

  useEffect(() => {
    sessionUser().then(res => {
      setUser(res.data.user);
    });  
  }, [])

  return (
    <>
      <div className="bg-white shadow overflow-hidden sm:rounded-lg mb-8">
        <div className="px-4 py-2 sm:px-6">
          <h3 className="text-lg leading-6 font-bold text-gray-900">
            Education and Job Information
        </h3>
        </div>
        <div className="border-t border-gray-200">
          <dl>
            <div className="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Education Type
              </dt>
              <dd className="capitalize mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {user.education_type ? user.education_type : 'N/A'}
              </dd>
            </div>
            <div className="bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Education Title
              </dt>
              <dd className="capitalize mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {user.education_title ? user.education_title : 'N/A'}
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Occupation Type
              </dt>
              <dd className="capitalize mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {user.occupation_type ? user.occupation_type : 'N/A'}
              </dd>
            </div>
            <div className="bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Occupation Title
              </dt>
              <dd className="capitalize mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {user.occupation_title ? user.occupation_title : 'N/A'}
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Occupation Description
              </dt>
              <dd className="capitalize mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {user.occupation_description ? user.occupation_description : 'N/A'}
              </dd>
            </div>
            <div className="bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Salary
              </dt>
              <dd className="capitalize mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {user.salary ? user.salary : 'N/A'}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </>
  );
}

